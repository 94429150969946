import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export const Layout = {
  window: {
    width,
    height,
  },
  isMobileDevice: width < 480,
  isTablets: width < 768,
  isSmallScreenLaptop: width < 1024,
  desktop: width > 1024
};
