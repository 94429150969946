import { mediaCloudActions } from '../mediaCloud-slice';
import { AppDispatch } from '../../index';
import { httpRequest } from '../../../utils/httpRequest';

import { backendUrl, backendGraphqlUrlPostfix } from 'react-native-dotenv';

export const deleteAlbum = (albumData: {
  name: string;
  releaseDate: number;
}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(mediaCloudActions.uploadingTrack(true));

    try {
      const graphqlQuery = {
        query: `
              mutation deleteAlbum($name: String!, $releaseDate: Int!) {
                deleteAlbum ( name: $name, releaseDate: $releaseDate ) {
                  __typename
                  ... on AlbumData {
                  _id,
                  name,
                  slug,
                  coverImage,
                  images,
                  releaseDate,
                  totalDiscs,
                  artist {
                      name,
                      date
                  }
                  tracks {
                      _id
                      trackNumber,
                      name,
                      discNumber,
                      mediaFile,
                      artist {
                          name,
                          date
                      },
                      date
                  },
                  date
                  }
                  ... on Error {
                      message
                  }
              }
            }
          `,
        variables: {
          name: albumData.name,
          releaseDate: albumData.releaseDate,
        },
      };

      await httpRequest({
        url: `${backendUrl + backendGraphqlUrlPostfix}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      dispatch(mediaCloudActions.uploadingTrack(false));
    } catch (err) {
      dispatch(mediaCloudActions.uploadingTrack(false));
      dispatch(mediaCloudActions.setAlbumError(err.message));
    }
  };
};
