import { useRef } from 'react';
import { View, StyleSheet } from 'react-native';

import { Audio } from 'expo-av';

import { PlayControl } from './Controls/PlayControl';
import { PreviousControl } from './Controls/PreviousControl';
import { NextControl } from './Controls/NextControl';
import { TrackSlider } from './Controls/TrackSlider';
import { VolumeSlider } from './Controls/VolumeSlider';

import { PlayingTitle } from './InfoDisplay/PlayingTitle';
import { PlayingDisc } from './InfoDisplay/PlayingDisc';
import { PlayingTrack } from './InfoDisplay/PlayingTrack';

import { selectedColors } from '../../../constants/Colors';

type BottomAudioPlayerProps = {
  configuration: {
    style?: object;
    playbackStatus: {
      isLoaded: Boolean;
    };
    volume?: number;
    audioMedia: {
      disc: string;
      track: string;
      title: string;
    };
    audioInstance: Audio.Sound;
    playingHandler: {
      setIsPlaying: (status: boolean) => void;
      isPlaying: boolean;
    };
    playPrevious?: () => void;
    playNext?: () => void;
    manualSeekingHandler: {
      setManualSeeking: (position: number) => void;
      manualSeeking: number;
    };
  };
};

export const BottomAudioPlayer = (props: BottomAudioPlayerProps) => {
  const {
    style,
    playbackStatus,
    volume,
    audioMedia,
    audioInstance,
    playingHandler,
    playPrevious,
    playNext,
    manualSeekingHandler,
  } = props.configuration;

  const playControlRef = useRef();
  const previousControlRef = useRef();
  const nextControlRef = useRef();

  return (
    <View style={[style, styles.player]}>
      <View style={styles.playerControls}>
        <View style={styles.songPlaying}>
          <PlayingDisc
            audioMedia={{
              totalDiscs: audioMedia?.totalDiscs,
              disc: audioMedia?.disc,
            }}
          />
          <PlayingTrack
            audioMedia={{
              title: audioMedia?.title,
              track: audioMedia?.track,
            }}
          />
          <PlayingTitle audioMedia={{ title: audioMedia?.title }} />
        </View>
        <PreviousControl
          ref={previousControlRef}
          playbackStatus={playbackStatus}
          playPrevious={playPrevious}
        />
        <PlayControl
          ref={playControlRef}
          audioInstance={audioInstance}
          playbackStatus={playbackStatus}
          playingHandler={playingHandler}
        />
        <NextControl
          ref={nextControlRef}
          playbackStatus={playbackStatus}
          playNext={playNext}
        />
      </View>
      <TrackSlider
        audioInstance={audioInstance}
        playbackStatus={playbackStatus}
        manualSeekingHandler={manualSeekingHandler}
      />
      <VolumeSlider
        audioInstance={audioInstance}
        playbackStatus={playbackStatus}
        volume={volume}
      />
    </View>
  );
};

const borderOn = false;

const styles = StyleSheet.create({
  player: {
    shadowColor: selectedColors.Black,
    shadowOffset: { width: 0, height: -10 },
    shadowOpacity: 0.3,
    shadowRadius: 16,
  },
  playerControls: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  songPlaying: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%',
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
});
