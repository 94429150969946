import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';

import MusicScreen from '../screens/MediaCloud/Music/MusicScreen';
import PlayListScreen from '../screens/MediaCloud/Music/PlayListScreen';
import { Wolftech_logo_only } from '../components/Wolftech_logo_only';
import { AuthTokenTime } from '../components/AuthTokenTime';
import { appleColors, selectedColors } from '../constants/Colors';
import { Layout } from '../constants/Layout';

import { mediaCloudActions } from '../store/MediaCloud/mediaCloud-slice';

export default function MediaCloudMusicNavigator() {
  const MediaCloudMusicStack = createStackNavigator();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mediaCloudActions.mediaCloudHeaderShown(false));

    return () => {
      dispatch(mediaCloudActions.mediaCloudHeaderShown(true));
    };
  });

  let headerStyle = {
    backgroundColor: appleColors.Gray_5.Dark,
  };
  if (Layout.isMobileDevice) {
    headerStyle.height = 40;
  }

  return (
    <MediaCloudMusicStack.Navigator
      initialRouteName='MusicStack'
      screenOptions={{
        headerTitle: (props) => (
          <Wolftech_logo_only
            title={props.children}
            size={Layout.isMobileDevice ? 'small' : 'large'}
          />
        ),
        headerRight: () => <AuthTokenTime />,
        headerStyle,
        animationEnabled: true,
        headerTintColor: selectedColors.White,
        presentation: 'card',
        headerShown: true,
      }}
    >
      <MediaCloudMusicStack.Screen
        name='Library'
        component={MusicScreen}
        options={{ title: 'Music Library', animationEnabled: true }}
      />
      <MediaCloudMusicStack.Screen
        name='PlayList'
        component={PlayListScreen}
        options={{ animationEnabled: true }}
      />
    </MediaCloudMusicStack.Navigator>
  );
}
