export const httpRequest = (requestConfig: {
  url: string;
  credentials?: 'include' | 'omit';
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: {};
  body?: any;
}) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await fetch(requestConfig.url, {
        credentials: requestConfig.credentials
          ? requestConfig.credentials
          : 'include',
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: requestConfig.headers ? requestConfig.headers : {},
        body:
          requestConfig.body &&
          requestConfig.headers &&
          Object.values(requestConfig.headers)[0] === 'application/json'
            ? JSON.stringify(requestConfig.body)
            : requestConfig.body
            ? requestConfig.body
            : null,
      });

      const resData = await response.json();

      if (!response.ok) {
        resolve(resData.errors[0].message);
      }
      resData.data ? resolve(resData.data) : resolve(resData);
    } catch (err) {
      reject({
        message: 'The connection went offline with the backend!',
        detailedInfo: err,
      });
    }
  });
};
