import { forwardRef } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Audio } from 'expo-av';

import { Layout } from '../../../../constants/Layout';

type PlayControlProps = {
  audioInstance: Audio.Sound;
  playbackStatus: {
    isLoaded: Boolean;
  };
  playingHandler: {
    setIsPlaying: (status: boolean) => void;
    isPlaying: boolean;
  };
};

export const PlayControl = forwardRef((props: PlayControlProps, ref) => {
  const { audioInstance, playbackStatus, playingHandler } = props;

  const playHandler = () => {
    if (playingHandler.isPlaying) {
      audioInstance.pauseAsync();
      playingHandler.setIsPlaying(false);
    } else {
      audioInstance.playAsync();
      playingHandler.setIsPlaying(true);
    }
  };

  return (
    <TouchableWithoutFeedback
      style={{ width: Layout.window.width * 0.2 }}
      disabled={playbackStatus.isLoaded ? false : true}
      onPress={playHandler}
    >
      <View ref={ref} style={{ cursor: 'pointer' }}>
        <Icon
          name={
            playingHandler.isPlaying
              ? playbackStatus.isLoaded
                ? 'pause'
                : 'pause-outline'
              : playbackStatus.isLoaded
              ? 'play'
              : 'play-outline'
          }
          type={'ionicon'}
          size={40}
        />
      </View>
    </TouchableWithoutFeedback>
  );
});
