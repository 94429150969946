import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';

import MovieScreen from '../screens/MediaCloud/Movie/MovieScreen';
import CinemaScreen from '../screens/MediaCloud/Movie/CinemaScreen';
import { Wolftech_logo_only } from '../components/Wolftech_logo_only';
import { AuthTokenTime } from '../components/AuthTokenTime';
import { appleColors, selectedColors } from '../constants/Colors';
import { Layout } from '../constants/Layout';

import { mediaCloudActions } from '../store/MediaCloud/mediaCloud-slice';

export default function MediaCloudMovieNavigator() {
  const MediaCloudMovieStack = createStackNavigator();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mediaCloudActions.mediaCloudHeaderShown(false));

    return () => {
      dispatch(mediaCloudActions.mediaCloudHeaderShown(true));
    };
  });

  let headerStyle = {
    backgroundColor: appleColors.Gray_5.Dark,
  };
  if (Layout.isMobileDevice) {
    headerStyle.height = 40;
  }

  return (
    <MediaCloudMovieStack.Navigator
      initialRouteName='MusicStack'
      screenOptions={{
        headerTitle: (props) => (
          <Wolftech_logo_only
            title={props.children}
            size={Layout.isMobileDevice ? 'small' : 'large'}
          />
        ),
        headerRight: () => <AuthTokenTime />,
        headerStyle,
        animationEnabled: true,
        headerTintColor: selectedColors.White,
        presentation: 'card',
        headerShown: true,
      }}
    >
      <MediaCloudMovieStack.Screen
        name='Library'
        component={MovieScreen}
        options={{ title: 'Movie Library', animationEnabled: true }}
      />
      <MediaCloudMovieStack.Screen
        name='Cinema'
        component={CinemaScreen}
        options={{ animationEnabled: true }}
      />
    </MediaCloudMovieStack.Navigator>
  );
}
