import { TouchableWithoutFeedback, View, Linking } from 'react-native';
import { Icon } from 'react-native-elements';
import { Hoverable } from 'react-native-web-hover';

import { appleColors } from '../../../constants/Colors';

type SocialMediaIconProps = {
  config: {
    icon: string;
    link: string;
    color: string;
  };
};

export default function SocialMediaIcon(props: SocialMediaIconProps) {
  const { config } = props;
  return (
    <Hoverable style={{ marginHorizontal: 10 }}>
      {({ hovered }) => (
        <TouchableWithoutFeedback
          onPress={() => {
            Linking.openURL(config.link);
          }}
        >
          <View>
            <Icon
              style={{ cursor: 'pointer' }}
              name={config.icon}
              type='ionicon'
              size={30}
              color={hovered ? config.color : appleColors.Gray_1.Light}
            />
          </View>
        </TouchableWithoutFeedback>
      )}
    </Hoverable>
  );
}
