export const getImageSet = (srcset: string) => {
  let imageUrlStartings = [];
  let imageUrlEndings = [];
  let sources: [{ uri: string; height: number; width: number }] | [] = [];
  imageUrlStartings = [...srcset.matchAll(new RegExp('https://', 'gi'))].map(
    (a) => a.index
  );
  imageUrlEndings = [...srcset.matchAll(new RegExp('.jpg', 'gi'))].map(
    (a) => a.index
  );
  for (let x = 0; x < imageUrlStartings.length; x++) {
    sources.push({
      uri: srcset.substring(imageUrlStartings[x], imageUrlEndings[x] + 4),
      height: parseInt(
        srcset
          .substring(imageUrlStartings[x], imageUrlEndings[x] - 1)
          .split(',')[3]
      ),
      width: parseInt(
        srcset
          .substring(imageUrlStartings[x], imageUrlEndings[x] - 1)
          .split(',')[2]
      ),
    });
  }
  return sources;
};
