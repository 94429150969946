export const toHHMMSS = function (milliseconds) {
  var sec_num = parseInt(milliseconds / 1000);
  var days = Math.floor(sec_num / 86400);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (days > 0) {
    hours = hours - 24 * days;
    return (
      days +
      ' Days ' +
      hours +
      ' Hours ' +
      minutes +
      ' Minutes ' +
      seconds +
      ' Seconds'
    );
  } else if (hours > 0) {
    return hours + ' Hours ' + minutes + ' Minutes ' + seconds + ' Seconds';
  } else if (minutes > 0) {
    return minutes + ' Minutes ' + seconds + ' Seconds';
  } else if (seconds > 0) {
    return seconds + ' Seconds';
  } else if (seconds === 0) {
    return milliseconds + ' Milliseconds (not enough for seconds!)';
  } else {
    return (
      days +
      ' Days ' +
      hours +
      ' Hours ' +
      minutes +
      ' Minutes ' +
      seconds +
      ' Seconds'
    );
  }
};

export const toHHMMSS_Shorthand = function (milliseconds) {
  var sec_num = parseInt(milliseconds / 1000);
  var days = Math.floor(sec_num / 86400).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  var hours = Math.floor(sec_num / 3600).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  var minutes = Math.floor((sec_num - hours * 3600) / 60).toLocaleString(
    'en-US',
    { minimumIntegerDigits: 2, useGrouping: false }
  );
  var seconds = (sec_num - hours * 3600 - minutes * 60).toLocaleString(
    'en-US',
    { minimumIntegerDigits: 2, useGrouping: false }
  );

  if (days > 0) {
    hours = hours - 24 * days;
    return days + ' D ' + hours + ':' + minutes + ':' + seconds;
  } else if (hours > 0) {
    return hours + ':' + minutes + ':' + seconds;
  } else if (minutes > 0) {
    return minutes + ':' + seconds;
  } else if (seconds > 0) {
    return seconds;
  } else if (seconds === 0) {
    return milliseconds + ' ms (not enough for seconds!)';
  } else {
    return days + ' D ' + hours + ':' + minutes + ':' + seconds;
  }
};
