import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { View, ActivityIndicator } from 'react-native';

import { CustomButton } from '../../components/CustomButton';
import { appleColors, selectedColors } from '../../constants/Colors';

import { logout } from '../../store/Auth/auth-actions';

import { backendUrl, backendGraphqlUrlPostfix } from 'react-native-dotenv';

export default function AccountScreen() {
  const { authLoading, user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <CustomButton
        title={'Logout'}
        titleObject={
          authLoading ? <ActivityIndicator size='small' /> : undefined
        }
        onPress={() => dispatch(logout(backendUrl + backendGraphqlUrlPostfix))}
        textStyle={{
          fontSize: 16,
          color: selectedColors.White,
          fontFamily: 'sf-pro',
        }}
        button={{
          height: 50,
          width: 270,
          color: appleColors.Blue.Dark,
          borderRadius: 25,
        }}
      />
    </View>
  );
}
