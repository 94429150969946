import { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Slider from '@react-native-community/slider';

import { Audio } from 'expo-av';

import { dateCalculations } from '../../../../utils/dateCalculations';

import { appleColors } from '../../../../constants/Colors';

type TrackSliderProps = {
  audioInstance: Audio.Sound;
  playbackStatus: {
    isLoaded: Boolean;
  };
  manualSeekingHandler: {
    setManualSeeking: (position: number) => void;
    manualSeeking: number;
  };
};

export const TrackSlider = (props: TrackSliderProps) => {
  const { audioInstance, playbackStatus, manualSeekingHandler } = props;

  const [durationMillis, setDurationMillis] = useState(Number);
  const [positionMillis, setPositionMillis] = useState(Number);

  useEffect(() => {
    let isMounted = true;
    const interval = setInterval(async () => {
      const status = await audioInstance.getStatusAsync();
      if (isMounted) {
        setDurationMillis(status.durationMillis);
        setPositionMillis(status.positionMillis);
      }
    }, 1000);
    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [playbackStatus.isLoaded]);

  const onSlidingStartHandler = (value) => {
    //console.log(`onSlidingStart: ${Math.round(value)}`);
    manualSeekingHandler.setManualSeeking(Math.round(value));
  };

  const onSlidingCompleteHandler = (value) => {
    //console.log(`onSlidingComplete: ${Math.round(value)}`);
    manualSeekingHandler.setManualSeeking(0);
    playbackStatus.isLoaded
      ? audioInstance.setPositionAsync(Math.round(value))
      : undefined;
  };

  const TrackPositionForward = () => {
    return (
      <Text style={styles.sliderText}>
        {durationMillis
          ? dateCalculations.millisToMinutesAndSeconds(positionMillis)
          : `0:00`}
      </Text>
    );
  };

  const TrackPositionBackward = () => {
    return (
      <Text style={styles.sliderText}>
        {`-${
          durationMillis
            ? dateCalculations.millisToMinutesAndSeconds(
                durationMillis - positionMillis
              )
            : `0:00`
        }`}
      </Text>
    );
  };

  return (
    <View style={styles.playerTrackSlider}>
      <TrackPositionForward />
      <Slider
        style={styles.slider}
        minimumValue={0}
        maximumValue={durationMillis ? durationMillis : 1}
        value={
          manualSeekingHandler.manualSeeking !== 0
            ? manualSeekingHandler.manualSeeking
            : positionMillis
        }
        step={durationMillis ? durationMillis / 100 : 1}
        onSlidingStart={onSlidingStartHandler}
        onSlidingComplete={onSlidingCompleteHandler}
        //onValueChange={(value) => {
        //  console.log(`onValueChange: ${Math.round(value)}`);
        //  playbackStatus.isLoaded
        //    ? audioInstance.setPositionAsync(Math.round(value))
        //    : undefined;
        //}}
        minimumTrackTintColor={appleColors.Blue.Light}
        maximumTrackTintColor={appleColors.Gray_5.Light}
      />
      <TrackPositionBackward />
    </View>
  );
};

const styles = StyleSheet.create({
  playerTrackSlider: {
    height: 40,
    marginHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    //borderColor: 'green',
    //borderWidth: 1,
  },
  slider: {
    marginHorizontal: 10,
    width: '100%',
  },
  sliderText: {
    fontSize: 16,
    fontFamily: 'sf-pro',
  },
});
