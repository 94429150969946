import { forwardRef } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'react-native-elements';

import { Layout } from '../../../../constants/Layout';

type PreviousControlProps = {
  playbackStatus: {
    isLoaded: Boolean;
  };
  playPrevious?: () => void;
};

export const PreviousControl = forwardRef(
  (props: PreviousControlProps, ref) => {
    const { playbackStatus, playPrevious } = props;

    return (
      <TouchableWithoutFeedback
        disabled={playbackStatus.isLoaded ? false : true}
        style={{ width: Layout.window.width * 0.2 }}
        onPress={() => {
          playPrevious ? playPrevious() : undefined;
        }}
      >
        <View ref={ref} style={{ cursor: 'pointer' }}>
          <Icon
            name={playbackStatus.isLoaded ? 'play-back' : 'play-back-outline'}
            type={'ionicon'}
            size={40}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }
);
