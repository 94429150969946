import React, { useReducer, useCallback } from 'react';
import { RootState } from '../../store';
import { ActivityIndicator, View, Text } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { CustomButton } from '../../components/CustomButton';
import { TextInput } from '../../components/TextInput';

import { Layout } from '../../constants/Layout';
import { appleColors, selectedColors } from '../../constants/Colors';

import {
  formValidatorReducer,
  formValidatorReducerActions,
} from '../../utils/formValidatorReducer';
import { signup } from '../../store/Auth/auth-actions';

const scrollViewPageInsideWidth =
  Layout.window.width - Layout.window.width * 0.15;

const SignupForm = ({
  isSignup,
  graphqlBackendUrl,
  enableSignup,
  changeForm,
}: any) => {
  const { authLoading } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const initSignupFormState = () => {
    return {
      inputValues: { name: '', email: '', password: '', confirmPassword: '' },
      inputValidities: {
        name: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
      formIsValid: false,
      formFieldCompareIsValid: false,
    };
  };

  const [signupFormState, dispatchSignupFormState] = useReducer(
    formValidatorReducer,
    {},
    initSignupFormState
  );

  const signupFormInputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchSignupFormState({
        type: formValidatorReducerActions.InputUpdate,
        payload: {
          compare: ['password', 'confirmPassword'],
          input: inputIdentifier,
          value: inputValue,
          isValid: inputValidity,
        },
      });
    },
    [dispatchSignupFormState]
  );

  const signupAction = () => {
    dispatch(
      signup({
        graphqlBackendUrl,
        userInput: {
          name: signupFormState.inputValues.name,
          email: signupFormState.inputValues.email,
          password: signupFormState.inputValues.password,
        },
      })
    );

    changeForm();
  };

  return (
    <View
      style={{
        width: Layout.isMobileDevice ? '90%' : '50%',
        paddingHorizontal: '0.5%',
      }}
    >
      <TextInput
        id='name'
        label='Full name'
        placeholder={'Andrew Wolf'}
        editable={enableSignup}
        keyboardType='default'
        required
        minLength={3}
        autoCapitalize='none'
        errorText='Please enter a name.'
        onInputChange={signupFormInputChangeHandler}
        width={Layout.isMobileDevice ? '48%' : '49%'}
      />
      <TextInput
        id='email'
        label='Email'
        placeholder={'example@email.com'}
        editable={enableSignup}
        keyboardType='email-address'
        required
        email
        autoCapitalize='none'
        errorText='Please enter a valid email address.'
        onInputChange={signupFormInputChangeHandler}
        width={Layout.isMobileDevice ? '48%' : '49%'}
      />
      <TextInput
        id='password'
        label='Password'
        placeholder={'********'}
        editable={enableSignup}
        keyboardType='default'
        secureTextEntry
        required
        minLength={8}
        autoCapitalize='none'
        errorText='Please enter a valid password.'
        onInputChange={signupFormInputChangeHandler}
        width={Layout.isMobileDevice ? '48%' : '49%'}
      />
      <TextInput
        id='confirmPassword'
        label='Confirm Password'
        placeholder={'********'}
        editable={enableSignup}
        keyboardType='default'
        secureTextEntry
        required
        minLength={8}
        autoCapitalize='none'
        errorText='Please enter a valid password.'
        onInputChange={signupFormInputChangeHandler}
        width={Layout.isMobileDevice ? '48%' : '49%'}
      />
      {!signupFormState.formFieldCompareIsValid ? (
        <View
          style={{
            width: scrollViewPageInsideWidth,
          }}
        >
          <Text
            style={{
              color: 'red',
              fontSize: 12,
            }}
          >
            Passwords did not match!
          </Text>
        </View>
      ) : null}
      <CustomButton
        title={enableSignup ? 'Sign up' : 'Sign up is closed!'}
        titleObject={
          authLoading ? <ActivityIndicator size='small' /> : undefined
        }
        disabled={
          !isSignup
            ? true
            : !signupFormState.formIsValid
            ? true
            : authLoading
            ? true
            : false
        }
        onPress={signupAction}
        textStyle={{
          fontSize: 18,
          color: selectedColors.White,
          fontFamily: 'sf-pro',
        }}
        button={{
          marginVertical: 20,
          height: 50,
          color: appleColors.Gray_4.Dark,
          width: Layout.isMobileDevice ? '48%' : '49%',
          borderRadius: 10,
        }}
      />
    </View>
  );
};

export default SignupForm;
