import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { Text, View } from 'react-native';
import { NavigationContainer, LinkingOptions } from '@react-navigation/native';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as Linking from 'expo-linking';

import SplashScreen from '../screens/Auth/SplashScreen';
import HomeNavigator from '../navigation/HomeNavigator';
import MediaCloudNavigator from './MediaCloudNavigator';
import { Auth } from '../screens/Auth/Auth';
import AccountScreen from '../screens/Auth/AccountScreen';

import { getAuth } from '../store/Auth/auth-actions';

import { Wolftech_logo_only } from '../components/Wolftech_logo_only';
import { AuthTokenTime } from '../components/AuthTokenTime';
import { appleColors, selectedColors } from '../constants/Colors';

import { Layout } from '../constants/Layout';

import { backendUrl, backendGraphqlUrlPostfix } from 'react-native-dotenv';

const prefix = Linking.createURL('/');
const linking: LinkingOptions<object> = {
  prefixes: [prefix],
  config: {
    screens: {
      HomeStack: {
        //path: '/',
        initialRouteName: 'Home',
        screens: {
          Home: 'home',
          CV: 'cv',
          Travel: 'travel',
        },
      },
      MediaCloudStack: {
        path: 'mediacloud',
        initialRouteName: 'Service',
        screens: {
          Service: { path: 'service' },
          MusicStack: {
            path: 'music',
            initialRouteName: 'Library',
            screens: {
              Library: { path: 'library' },
              PlayList: {
                path: 'playlist/:album_id',
                parse: {
                  year: Number,
                },
                stringify: {
                  date: (date: any) => {
                    const d = new Date(date);
                    return (
                      d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()
                    );
                  },
                },
              },
            },
          },
          MovieStack: {
            path: 'movie',
            initialRouteName: 'Library',
            screens: {
              Library: { path: 'library' },
              Cinema: {
                path: 'cinema/:movie_id',
                parse: {
                  year: Number,
                },
                stringify: {
                  date: (date: any) => {
                    const d = new Date(date);
                    return (
                      d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()
                    );
                  },
                },
              },
            },
          },
        },
      },
      Auth: 'auth',
      Account: 'account',
    },
  },
};

const RootNavigator = () => {
  const RootTabs = createBottomTabNavigator();
  const user = useSelector((state: RootState) => state.auth.user);

  const AuthComponent = () => {
    return (
      <Auth
        graphqlBackendUrl={backendUrl + backendGraphqlUrlPostfix}
        enableSignup={true}
      />
    );
  };

  let tabDefaultOptions = {
    tabBarIcon: () => <View />,
    tabBarActiveTintColor: selectedColors.White,
    tabBarActiveBackgroundColor: appleColors.Gray_2.Dark,
    tabBarInactiveTintColor: selectedColors.Black,
  };
  if (Layout.isMobileDevice) {
    tabDefaultOptions.tabBarStyle = {
      height: 40,
    };
    tabDefaultOptions.tabBarLabelStyle = {
      paddingBottom: 12,
    };
  }

  let headerStyle = {};
  if (Layout.isMobileDevice) {
    headerStyle.height = 40;
  }

  return (
    <RootTabs.Navigator>
      <RootTabs.Screen
        name='HomeStack'
        component={HomeNavigator}
        options={{
          title: 'Home',
          headerShown: false,
          ...tabDefaultOptions,
        }}
      />
      {!user.userId ? (
        <RootTabs.Screen
          name='Auth'
          component={AuthComponent}
          options={{
            title: 'Login',
            headerTitle: () => (
              <Wolftech_logo_only
                theme={'dark'}
                size={Layout.isMobileDevice ? 'small' : 'large'}
              />
            ),
            headerStyle,
            //headerShown: false,
            ...tabDefaultOptions,
          }}
        />
      ) : (
        <Fragment>
          {user.role === 'admin' ? (
            <RootTabs.Screen
              name='MediaCloudStack'
              component={MediaCloudNavigator}
              options={{
                title: 'MediaCloud',
                headerShown: false,
                ...tabDefaultOptions,
              }}
            />
          ) : null}
          <RootTabs.Screen
            name='Account'
            component={AccountScreen}
            options={{
              headerTitle: (props) => (
                <Wolftech_logo_only
                  title={props.children}
                  theme={'dark'}
                  size={Layout.isMobileDevice ? 'small' : 'large'}
                />
              ),
              headerRight: () => <AuthTokenTime theme={'dark'} />,
              headerStyle,
              ...tabDefaultOptions,
            }}
          />
        </Fragment>
      )}
    </RootTabs.Navigator>
  );
};

export default function Navigation() {
  const authLoading = useSelector((state: RootState) => state.auth.authLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAuth(backendUrl + backendGraphqlUrlPostfix));
  }, []);

  return authLoading ? (
    <SplashScreen />
  ) : (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <RootNavigator />
    </NavigationContainer>
  );
}
