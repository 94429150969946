import { useSelector } from 'react-redux';
import { RootState } from '../store';

import { createStackNavigator } from '@react-navigation/stack';

import MediaCloudScreen from '../screens/MediaCloud/MediaCloudScreen';
import MediaCloudMusicNavigator from './MediaCloudMusicNavigator';
import MediaCloudMovieNavigator from './MediaCloudMovieNavigator';
import { Wolftech_logo_only } from '../components/Wolftech_logo_only';
import { AuthTokenTime } from '../components/AuthTokenTime';
import { appleColors, selectedColors } from '../constants/Colors';
import { Layout } from '../constants/Layout';

export default function MediaCloudNavigator() {
  const MediaCloudStack = createStackNavigator();

  const mediaCloudHeaderShown = useSelector(
    (state: RootState) => state.mediaCloud.mediaCloudHeaderShown
  );

  let headerStyle = {
    backgroundColor: appleColors.Gray_5.Dark,
  };
  if (Layout.isMobileDevice) {
    headerStyle.height = 40;
  }

  return (
    <MediaCloudStack.Navigator
      initialRouteName='MediaCloudStack'
      screenOptions={{
        headerTitle: (props) => (
          <Wolftech_logo_only
            title={props.children}
            size={Layout.isMobileDevice ? 'small' : 'large'}
          />
        ),
        headerRight: () => <AuthTokenTime />,
        headerStyle,
        animationEnabled: true,
        headerTintColor: selectedColors.White,
        presentation: 'card',
        headerShown: mediaCloudHeaderShown,
      }}
    >
      <MediaCloudStack.Screen
        name='Service'
        component={MediaCloudScreen}
        options={{ title: 'MediaCloud', animationEnabled: true }}
      />
      <MediaCloudStack.Screen
        name='MusicStack'
        component={MediaCloudMusicNavigator}
        options={{ title: 'Music', animationEnabled: true }}
      />
      <MediaCloudStack.Screen
        name='MovieStack'
        component={MediaCloudMovieNavigator}
        options={{ title: 'Movie', animationEnabled: true }}
      />
    </MediaCloudStack.Navigator>
  );
}
