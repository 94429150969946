import { configureStore } from '@reduxjs/toolkit';

import { authSlice } from './Auth/auth-slice';
import { introductionSlice } from './Introduction/introduction-slice';
import { mediaCloudSlice } from './MediaCloud/mediaCloud-slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    introduction: introductionSlice.reducer,
    mediaCloud: mediaCloudSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
