import { View, ActivityIndicator } from 'react-native';
import { Wolftech_logo } from '../../components/Wolftech_logo';
import { Layout } from '../../constants/Layout';

export default function SplashScreen() {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Wolftech_logo
        size={Layout.isMobileDevice ? 'small' : 'large'}
        theme='dark'
      />
      <ActivityIndicator
        size={Layout.isMobileDevice ? 'small' : 'large'}
        style={{ marginTop: Layout.isMobileDevice ? 5 : 20 }}
      />
    </View>
  );
}
