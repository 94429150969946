export const appleColors = {
  Red: { Light: 'rgba(255,59,48,1)', Dark: 'rgba(255,69,58,1)' },
  Orange: { Light: 'rgba(255,149,0,1)', Dark: 'rgba(255,159,10,1)' },
  Yellow: { Light: 'rgba(255,204,0,1)', Dark: 'rgba(255,214,10,1)' },
  Green: { Light: 'rgba(52,199,89,1)', Dark: 'rgba(48,209,88,1)' },
  Mint: { Light: 'rgba(0,199,190,1)', Dark: 'rgba(102,212,207,1)' },
  Teal: { Light: 'rgba(48,176,199,1)', Dark: 'rgba(64,200,224,1)' },
  Cyan: { Light: 'rgba(50,173,230,1)', Dark: 'rgba(100,210,255,1)' },
  Blue: { Light: 'rgba(0,122,255,1)', Dark: 'rgba(10,132,255,1)' },
  Indigo: { Light: 'rgba(88,86,214,1)', Dark: 'rgba(94,92,230,1)' },
  Purple: { Light: 'rgba(175,82,222,1)', Dark: 'rgba(191,90,242,1)' },
  Pink: { Light: 'rgba(255,45,85,1)', Dark: 'rgba(255,55,95,1)' },
  Brown: { Light: 'rgba(162,132,94,1)', Dark: 'rgba(172,142,104,1)' },

  Gray_1: { Light: 'rgba(142,142,147,1)', Dark: 'rgba(142,142,147,1)' },
  Gray_2: { Light: 'rgba(174,174,178,1)', Dark: 'rgba(99,99,102,1)' },
  Gray_3: { Light: 'rgba(199,199,204,1)', Dark: 'rgba(72,72,74,1)' },
  Gray_4: { Light: 'rgba(209,209,214,1)', Dark: 'rgba(58,58,60,1)' },
  Gray_5: { Light: 'rgba(229,229,234,1)', Dark: 'rgba(44,44,46,1)' },
  Gray_6: { Light: 'rgba(242,242,247,1)', Dark: 'rgba(28,28,30,1)' },
};

export const flatuicolors = {
  british_palette: {
    Protoss_Pylon: '#00a8ff',
    Periwinkle: '#9c88ff',
    Rise_N_Shine: '#fbc531',
    Download_Progress: '#4cd137',
    Seabrook: '#487eb0',
    Vanadyl_Blue: '#0097e6',
    Matt_Purple: '#8c7ae6',
    Nanohanacha_Gold: '#e1b12c',
    Skirret_Green: '#44bd32',
    Naval: '#40739e',
    Nasturcian_Flower: '#e84118',
    Lynx_White: '#f5f6fa',
    Blueberry_Soda: '#7f8fa6',
    Mazarine_Blue: '#273c75',
    Blue_Nights: '#353b48',
    Harley_Davidson_Orange: '#c23616',
    Hint_of_Pensive: '#dcdde1',
    Chain_Gang_Grey: '#718093',
    Pico_Void: '#192a56',
    Electromagnetic: '#2f3640',
  },
  french_palette: {
    Flat_Flesh: '#fad390',
    Melon_Melody: '#f8c291',
    Livid: '#6a89cc',
    Spray: '#82ccdd',
    Paradise_Green: '#b8e994',
    Squash_Blossom: '#f6b93b',
    Mandarin_Red: '#e55039',
    Azraq_Blue: '#4a69bd',
    Dupain: '#60a3bc',
    Aurora_Green: '#78e08f',
    Iceland_Poppy: '#fa983a',
    Tomato_Red: '#eb2f06',
    Yuè_Guāng_Lán_Blue: '#1e3799',
    Good_Samaritan: '#3c6382',
    Waterfall: '#38ada9',
    Carrot_Orange: '#e58e26',
    Jalapeno_Red: '#b71540',
    Dark_Sapphire: '#0c2461',
    Forest_Blues: '#0a3d62',
    Reef_Encounter: '#079992',
  },
};

export const selectedColors = {
  White: '#fff',
  Black: '#000',
  LeaterBrown: '#836539',
  US_Dollar_Green: '#228D57',
  IMDbYellow: '#F3CE13',
  linkedinBlue: '#0077b5',
  facebookBlue: '#4267B2',
  twitterBlue: '#1DA1F2',
  instagram: '#C13584',
  appleGray: '#555555',
  googlePlayBlue: '#3bccff',

  Overlay: { Light: 'rgba(36, 36, 36, 0.7)', Dark: 'rgba(74, 74, 74, 0.7)' },
};

export const themedColors = {
  light: {
    text: selectedColors.Black,
    background: appleColors.Gray_5.Light,
    elementBackground: selectedColors.White,
    modalOverlayBackground: selectedColors.Overlay.Light,
    shadowColor: selectedColors.Black,
    tint: appleColors.Teal.Light,
    tabIconDefault: appleColors.Gray_3.Light,
    tabIconSelected: appleColors.Teal.Light,
    trackColor: appleColors.Blue.Light,
    thumbColor: appleColors.Blue.Light,
    borderColor: appleColors.Gray_4.Light,
  },
  dark: {
    text: selectedColors.White,
    background: appleColors.Gray_5.Dark,
    elementBackground: appleColors.Gray_6.Dark,
    modalOverlayBackground: selectedColors.Overlay.Dark,
    shadowColor: appleColors.Gray_6.Light,
    tint: selectedColors.White,
    tabIconDefault: appleColors.Gray_3.Light,
    tabIconSelected: selectedColors.White,
    trackColor: appleColors.Blue.Dark,
    thumbColor: appleColors.Blue.Dark,
    borderColor: appleColors.Gray_4.Dark,
  },
};
