import { Fragment, useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { selectedColors } from '../constants/Colors';
import { Layout } from '../constants/Layout';
import { RootState } from '../store';

import { toHHMMSS_Shorthand } from '../utils/millisecondsToHHMMSS';

export const AuthTokenTime = (props: { theme?: 'light' | 'dark' }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [authState, setAuthState] = useState<{ time: number }>();

  useEffect(() => {
    const interval = setInterval(
      () => setAuthState({ time: Date.now() }),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <View style={{ width: 430, flexDirection: 'row', marginRight: 20 }}>
      {user.userId && Layout.desktop ? (
        <Fragment>
          <Text
            style={[
              styles.text,
              {
                width: 340,
                color:
                  props?.theme === 'dark'
                    ? selectedColors.Black
                    : selectedColors.White,
              },
            ]}
          >
            {`Welcome: ${user.name}, your token valid until: `}
          </Text>
          <Text
            style={[
              styles.text,
              {
                width: 90,
                textAlign: 'right',
                color:
                  props?.theme === 'dark'
                    ? selectedColors.Black
                    : selectedColors.White,
              },
            ]}
          >
            {toHHMMSS_Shorthand(
              new Date(user.tokenValidTo).getTime() - new Date().getTime()
            )}
          </Text>
        </Fragment>
      ) : undefined}
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: 'sf-pro-bold',
    fontSize: 18,
  },
});
