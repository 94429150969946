import React, { useRef, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { Video, AVPlaybackStatus } from 'expo-av';
import { onFullscreenUpdate } from '../Configuration';

import { selectedColors } from '../../../constants/Colors';

type VideoPlayerProps = {
  configuration: {
    style?: object;
    videoMedia?: {
      mediaFile: string;
      posterImage: string;
    };
  };
};

export const VideoPlayer = (props: VideoPlayerProps) => {
  const { style, videoMedia } = props.configuration;

  const [playbackStatus, setPlaybackStatus] =
    useState<AVPlaybackStatus>(Object);

  const video = useRef(null);

  return (
    <View
      style={{
        ...style,
        backgroundColor: selectedColors.Black,
        justifyContent: 'center',
      }}
    >
      {!playbackStatus.isLoaded ? (
        <ActivityIndicator size='large' />
      ) : undefined}
      <Video
        ref={video}
        style={
          !playbackStatus.isLoaded
            ? {}
            : {
                ...style,
              }
        }
        source={{
          uri: encodeURI(videoMedia ? videoMedia.mediaFile : ''),
        }}
        //posterSource={{ uri: videoMedia ? videoMedia.posterImage : '' }}
        //usePoster={!playbackStatus.isLoaded}
        onFullscreenUpdate={onFullscreenUpdate}
        shouldPlay //No sound without it if the phone in silent mode
        useNativeControls
        resizeMode='contain'
        //isLooping
        onPlaybackStatusUpdate={setPlaybackStatus}
      />
    </View>
  );
};
