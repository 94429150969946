import { View, Text, StyleSheet } from 'react-native';

import { Wolftech_logo } from '../../../components/Wolftech_logo';
import SocialMediaIcon from './SocialMediaIcon';

import { appleColors, selectedColors } from '../../../constants/Colors';
import { Layout } from '../../../constants/Layout';

export default function FooterScreen() {
  return (
    <View style={styles.container}>
      <View style={styles.wolftechLogoContainer}>
        <Wolftech_logo size='small' />
      </View>
      <View style={styles.footerInfoContainer}>
        <View style={[styles.socialMediaLinks, styles.grayTopBorder]}>
          <SocialMediaIcon
            config={{
              icon: 'logo-linkedin',
              link: 'https://www.linkedin.com/in/farkas-andras-826316166/',
              color: selectedColors.linkedinBlue,
            }}
          />
          <SocialMediaIcon
            config={{
              icon: 'logo-facebook',
              link: 'https://www.facebook.com/andrewwolf.eu',
              color: selectedColors.facebookBlue,
            }}
          />
          <SocialMediaIcon
            config={{
              icon: 'logo-twitter',
              link: 'https://twitter.com/FarkasAndrs14',
              color: selectedColors.twitterBlue,
            }}
          />
          <SocialMediaIcon
            config={{
              icon: 'logo-instagram',
              link: 'https://www.instagram.com/andrewwolf.eu/',
              color: selectedColors.instagram,
            }}
          />
          <SocialMediaIcon
            config={{
              icon: 'logo-google-playstore',
              link: 'https://play.google.com/store/apps/developer?id=Andrew-Wolf',
              color: selectedColors.googlePlayBlue,
            }}
          />
          <SocialMediaIcon
            config={{
              icon: 'logo-apple',
              link: 'https://apps.apple.com/hu/developer/andras-farkas/id1602878509',
              color: selectedColors.appleGray,
            }}
          />
          <SocialMediaIcon
            config={{
              icon: 'logo-playstation',
              link: 'https://psnprofiles.com/Andrew--Wolf--',
              color: selectedColors.White,
            }}
          />
        </View>
        <View
          style={[
            styles.copyright,
            Layout.isMobileDevice ? undefined : styles.grayTopBorder,
          ]}
        >
          <Text style={styles.copyrightText}>
            Built by Andrew Wolf for its own purposes. Self introduction,
            technological details and personal content.{'\n'}Copyright ©
            {` ${new Date().getFullYear()}`} by Andrew Wolf. All rights
            reserved.
          </Text>
        </View>
      </View>
    </View>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  container: {
    paddingTop: Layout.isMobileDevice ? 25 : 50,
    alignItems: 'center',
    backgroundColor: appleColors.Gray_4.Dark,
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  wolftechLogoContainer: {
    width: 290,
    alignItems: 'center',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'red' : undefined,
  },
  footerInfoContainer: {
    marginVertical: Layout.isMobileDevice ? 25 : 50,
    width: '100%',
    flexDirection: Layout.isMobileDevice ? 'column' : 'row',
    justifyContent: 'center',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
  },
  grayTopBorder: {
    paddingTop: 20,
    marginHorizontal: Layout.isMobileDevice ? 10 : 200,
    borderTopWidth: Layout.isMobileDevice ? 1 : 2,
    borderTopColor: appleColors.Gray_1.Light,
  },
  socialMediaLinks: {
    flexDirection: 'row',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'orange' : undefined,
  },
  copyright: {
    marginHorizontal: Layout.isMobileDevice ? 16 : 200,
    paddingTop: Layout.isMobileDevice ? 20 : undefined,
    width: Layout.isMobileDevice ? 350 : 370,
    textAlign: Layout.isMobileDevice ? 'center' : 'left',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'yellow' : undefined,
  },
  copyrightText: {
    fontFamily: 'sf-pro',
    fontSize: Layout.isMobileDevice ? 12 : 14,
    color: selectedColors.White,
  },
});
