import { AppDispatch } from '../index';
import { authActions } from './auth-slice';
import { httpRequest } from '../../utils/httpRequest';

export const getAuth = (graphqlBackendUrl: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authActions.loading(true));

    try {
      const graphqlQuery = {
        query: `
        query {
          getAuth {
              __typename
              ... on AuthData { userId, role, name, email, tokenValidTo, createdAt }
              ... on Error { message }
          }
        }
        `,
      };

      const responseData = await httpRequest({
        url: graphqlBackendUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.getAuth.message === 'Not authenticated!') {
        dispatch(authActions.logout());
      } else {
        const { __typename, createdAt, ...user } = responseData.getAuth;

        dispatch(
          authActions.authenticate({
            user,
          })
        );
      }
      dispatch(authActions.loading(false));
    } catch (err) {
      dispatch(authActions.loading(false));
      return dispatch(authActions.setError(err));
    }
  };
};

export const signup = ({ graphqlBackendUrl, userInput }: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authActions.loading(true));

    try {
      const graphqlQuery = {
        query: `
        mutation CreateNewUser($email: String!, $name: String!, $password: String!) {
          createUser(userInput: {
            email: $email,
            name: $name,
            password: $password})
            {
              __typename
              ... on AuthData {
                  userId
                  name
                  email
                  role
                  tokenValidTo
                  createdAt
              }
              ... on Error {
                  code,
                  message
              }
            }
          }
      `,
        variables: {
          name: userInput.name,
          email: userInput.email,
          password: userInput.password,
        },
      };

      const responseData = await httpRequest({
        url: graphqlBackendUrl,
        credentials: 'omit',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.createUser.code) {
        dispatch(authActions.setError(responseData.createUser.message));
      }
      dispatch(authActions.loading(false));
    } catch (err) {
      dispatch(authActions.loading(false));
      return dispatch(authActions.setError(err));
    }
  };
};

export const login = ({ graphqlBackendUrl, deviceTypeKey, userInput }: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authActions.loading(true));

    try {
      const graphqlQuery = {
        query: `
      query UserLogin($email: String!, $password: String!, $deviceTypeKey: String) {
        login(email: $email, password: $password, deviceTypeKey: $deviceTypeKey) { 
          __typename
          ... on AuthData {
              userId
              role
              name
              email
              token
              tokenValidTo
              createdAt
              }
          ... on Error {
              code,
              message
            }
          }
        }
      `,
        variables: {
          email: userInput.email,
          password: userInput.password,
          deviceTypeKey,
        },
      };

      const responseData = await httpRequest({
        url: graphqlBackendUrl,
        /* Include happens automatically
        credentials: 'include',
        */
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.login.code) {
        dispatch(authActions.setError(responseData.login.message));
      } else {
        const { __typename, createdAt, ...user } = responseData.login;

        dispatch(
          authActions.authenticate({
            user,
          })
        );
      }
      dispatch(authActions.loading(false));
    } catch (err) {
      dispatch(authActions.loading(false));
      return dispatch(authActions.setError(err));
    }
  };
};

export const logout = (graphqlBackendUrl: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authActions.loading(true));

    try {
      const graphqlQuery = {
        query: `
      query {
        logout {
          __typename
          ... on AuthData {
          userId
          role
          name
          email
          tokenValidTo
          createdAt
          }
          ... on Error {
              code,
              message
          }
        }
      }
      `,
      };

      const responseData = await httpRequest({
        url: graphqlBackendUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      dispatch(authActions.logout());
      if (responseData.logout.code) {
        dispatch(authActions.setError(responseData.logout.message));
      }
      dispatch(authActions.loading(false));
    } catch (err) {
      dispatch(authActions.loading(false));
      return dispatch(authActions.setError(err));
    }
  };
};
