import { View, Text } from 'react-native';

type PlayingTitleProps = {
  audioMedia: {
    title?: string;
  };
};

export const PlayingTitle = (props: PlayingTitleProps) => {
  const { audioMedia } = props;

  return audioMedia?.title !== undefined ? (
    <View
      style={{
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 0,
        width: '76%',
      }}
    >
      <Text
        style={{
          fontSize: 14,
        }}
      >
        {audioMedia.title}
      </Text>
    </View>
  ) : null;
};
