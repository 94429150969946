import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { View, Text, FlatList, StyleSheet } from 'react-native';

import ResourceItem from './ResourceItem';

import { appleColors, selectedColors } from '../../../constants/Colors';
import { getResource } from '../../../store/Introduction/introduction-actions';
import { Layout } from '../../../constants/Layout';

export default function ResourcesScreen() {
  const introductionData = useSelector(
    (state: RootState) => state.introduction
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getResource());
  }, []);

  const renderResourceTypeItem = ({ item }: any) => {
    return (
      <View style={styles.resourcesType}>
        <Text style={[styles.resourcesTypeText]}>{item.resourceType}</Text>
        <View style={styles.resourceItemFlatlistContainer}>
          <FlatList
            numColumns={Layout.isMobileDevice ? 2 : 3}
            data={item.resourceItems}
            keyExtractor={(item, index) => index.toString()}
            renderItem={ResourceItem}
          />
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={introductionData.resourceData}
        keyExtractor={(item, index) => index.toString()}
        renderItem={renderResourceTypeItem}
      />
    </View>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  container: {
    paddingTop: Layout.isMobileDevice ? 50 : 100,
    backgroundColor: selectedColors.White,
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  resourcesType: {
    marginHorizontal: Layout.isMobileDevice ? '2%' : '15%',
    width: Layout.isMobileDevice ? '96%' : '70%',
    alignItems: 'center',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'red' : undefined,
  },
  resourcesTypeText: {
    color: appleColors.Gray_2.Dark,
    marginBottom: 60,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontFamily: 'sf-pro',
    fontSize: Layout.isMobileDevice ? 20 : 26,
    fontWeight: '300',
    textAlign: 'center',
    width: 600,
  },
  resourceItemFlatlistContainer: {
    marginBottom: 70,
    width: '100%',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
  },
});
