import { useEffect, useCallback, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { ActivityIndicator, View, FlatList, StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';

import { CustomButton } from '../../../components/CustomButton';
import { TextInput } from '../../../components/TextInput';
import fileUpload from '../../../utils/fileUpload';

import { AlbumElement } from './AlbumElement';

import { appleColors, selectedColors } from '../../../constants/Colors';
import { Layout } from '../../../constants/Layout';

import {
  getAlbums,
  uploadAudioTrack,
  deleteAlbum,
} from '../../../store/MediaCloud/mediaCloud-actions';
import { mediaCloudActions } from '../../../store/MediaCloud/mediaCloud-slice';

/* let MusicScreenRenders = {
  renderScreen: 0,
  renderList: 0,
  listItem: 0,
  renderListItem: 0,
}; */
export default function MusicScreen() {
  //MusicScreenRenders.renderScreen += 1;
  //console.log({ MusicScreenRenders });
  const dispatch = useDispatch();

  const albumsData = useSelector(
    (state: RootState) => state.mediaCloud.albumsData
  );
  const loading = useSelector(
    (state: RootState) => state.mediaCloud.albumsDataLoading
  );
  const error = useSelector(
    (state: RootState) => state.mediaCloud.albumsDataError
  );
  const uploadingTrack = useSelector(
    (state: RootState) => state.mediaCloud.uploadingTrack
  );
  const uploadingCover = useSelector(
    (state: RootState) => state.mediaCloud.uploadingCover
  );

  const initialState = { name: '', releaseDate: 0 };
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<{
    name: string;
    releaseDate: number;
  }>(initialState);
  const [filteredAlbumsData, setFilteredAlbumsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const loadAlbums = useCallback(() => {
    dispatch(getAlbums());
  }, [dispatch]);

  useEffect(() => {
    loadAlbums();
  }, []);

  useEffect(() => {
    if (uploadingTrack === false || uploadingCover === false) {
      loadAlbums();
    }
  }, [uploadingTrack, uploadingCover]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchHandler(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [albumsData, searchTerm]);

  const searchHandler = (searchTerm) => {
      if (albumsData.length > 0) {
        let albumFilter = [];
        let artistsFilter = [];
        let songFilter = [];
        albumFilter = albumsData.filter(
          (album) =>
            album.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
        for (const album of albumsData) {
          let artistMatch = album.artist.filter(
            (artist) =>
              artist.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          );
          let songMatch = album.tracks.filter(
            (track) =>
              track.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          );
          if (artistMatch.length > 0) {
            artistsFilter.push(album);
          }
          if (songMatch.length > 0) {
            songFilter.push(album);
          }
        }
        const mergedResults = [...albumFilter, ...artistsFilter, ...songFilter];
        const searchResults = mergedResults.filter(
          (item, pos) => mergedResults.indexOf(item) === pos
        );
        setFilteredAlbumsData(searchResults);
      }
    }

  const deleteAlbumHandler = () => {
    dispatch(
      deleteAlbum({
        name: showDeleteConfirmation.name,
        releaseDate: showDeleteConfirmation.releaseDate,
      })
    );
    setShowDeleteConfirmation(initialState);
  };

  const albumWidth = 300;
  const albumMarginHorizontal = 20;
  const numberOfColumnsWithoutMargin = Math.floor(
    Layout.window.width / albumWidth
  );
  const numberOfListColumns = Math.floor(
    (Layout.window.width -
      numberOfColumnsWithoutMargin * albumMarginHorizontal) /
      albumWidth
  );
  const flatlistMarginHorizontal = Math.floor(
    (Layout.window.width -
      numberOfListColumns * albumWidth -
      numberOfListColumns * albumMarginHorizontal) /
      2
  );

  const RenderList = useCallback(() => {
    //MusicScreenRenders.renderList += 1;
    //MusicScreenRenders.listItem = albumData.length;
    //console.log({ MusicScreenRenders });
    return (
      <FlatList
        style={{
          paddingHorizontal: flatlistMarginHorizontal,
        }}
        numColumns={numberOfListColumns}
        onRefresh={() => {
          loadAlbums();
        }}
        refreshing={loading}
        data={filteredAlbumsData
          .slice()
          .sort((a, b) => new Date(b.date) - new Date(a.date))}
        keyExtractor={(item) => item._id}
        renderItem={renderItem}
        getItemLayout={(data, index) => ({
          length: 1,
          offset: 1 * index,
          index,
        })}
        initialNumToRender={25}
      />
    );
  }, [filteredAlbumsData, loading]);

  const renderItem = ({ item }) => {
    //MusicScreenRenders.renderListItem += 1;
    //console.log({ MusicScreenRenders });
    return (
      <AlbumElement
        item={item}
        albumWidth={albumWidth}
        albumMarginHorizontal={albumMarginHorizontal}
        setShowDeleteConfirmation={setShowDeleteConfirmation}
      />
    );
  };

  return (
    <Fragment>
      <View style={styles.librariesContainer}>
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            id='search'
            placeholder={'Search for albums, artists and songs'}
            autoCapitalize='none'
            onInputChange={(_, inputValue) => {
              setSearchTerm(inputValue);
            }}
            width='90%'
          />
          <CustomButton
            title={Layout.desktop ? 'Upload new track!' : '+'}
            titleObject={
              uploadingTrack ? <ActivityIndicator size='small' /> : undefined
            }
            disabled={uploadingTrack ? true : false}
            onPress={async () => {
              const tracks = await fileUpload({
                type: 'audio/*',
                copyToCacheDirectory: false,
                multiple: true,
              });
              dispatch(uploadAudioTrack(tracks));
            }}
            textStyle={{
              fontSize: 16,
              color: selectedColors.White,
              fontFamily: 'sf-pro',
            }}
            button={{
              width: '10%',
              color: appleColors.Blue.Dark,
            }}
          />
        </View>
        <View style={styles.albumsContainer}>
          {loading ? (
            <ActivityIndicator size='large' />
          ) : error === 'No album found!' ? (
            <CustomButton
              title={'No album found!'}
              titleObject={
                loading ? <ActivityIndicator size='small' /> : undefined
              }
              disabled={loading ? true : false}
              onPress={loadAlbums}
              textStyle={{
                fontSize: 18,
                color: selectedColors.White,
                fontFamily: 'sf-pro',
              }}
              button={{
                marginVertical: 20,
                height: 50,
                width: 200,
                color: appleColors.Blue.Dark,
                borderRadius: 10,
              }}
            />
          ) : (
            <RenderList />
          )}
        </View>
      </View>
      <AwesomeAlert
        show={error && error !== 'No album found!' ? true : false}
        title='An Error Occurred!'
        message={error ? error : ''}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        //cancelText='No, cancel'
        confirmText='Ok'
        confirmButtonColor='#DD6B55'
        //onCancelPressed={}
        onConfirmPressed={() => {
          dispatch(mediaCloudActions.albumsErrorConfirmation());
        }}
      />
      <AwesomeAlert
        show={showDeleteConfirmation.name !== ''}
        overlayStyle={{ width: '100%', heiht: '100%' }}
        title='Delete Album!'
        message={`Are you sure to delete ${showDeleteConfirmation.name} album?`}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='Cancel'
        cancelButtonColor={appleColors.Blue.Dark}
        confirmText='Ok'
        confirmButtonColor={appleColors.Red.Dark}
        onCancelPressed={() => {
          setShowDeleteConfirmation(initialState);
        }}
        onConfirmPressed={deleteAlbumHandler}
      />
    </Fragment>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  librariesContainer: {
    flex: 1,
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
});
