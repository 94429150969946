import { View, Text } from 'react-native';
import Svg, { Path, G } from 'react-native-svg';
import { appleColors, selectedColors } from '../constants/Colors';

type wolftechLogoOnlyProps = {
  title?: string;
  size?: 'small' | 'large';
  theme?: 'light' | 'dark';
};

export const Wolftech_logo_only = (props: wolftechLogoOnlyProps) => {
  const lightColor =
    props?.theme === 'dark' ? appleColors.Gray_2.Dark : selectedColors.White;
  const darkColor = appleColors.Blue.Dark;

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text
        style={{
          fontSize: 18,
          marginRight: 10,
          color:
            props?.theme === 'dark'
              ? selectedColors.Black
              : selectedColors.White,
        }}
      >
        {props?.title || ''}
      </Text>
      <Svg
        height={props.size === 'small' ? '35' : '50'}
        width={props.size === 'small' ? '40' : '65'}
        viewBox={props.size === 'small' ? '0 0 200 200' : '0 0 100 100'}
      >
        <G
          transform={
            props.size === 'small'
              ? 'matrix(10, 0, 0, 10, 15, -35)'
              : 'matrix(7, 0, 0, 7, -10, -40)'
          }
          fill={lightColor}
        >
          <Path
            d='M 5.02 19.3 L 4.66 19.3 L 0.7799999999999999 6.7 L 1.4 6.7 L 4.84 17.94 L 4.96 17.94 L 5.34 17 L 1.94 6 L -0.2 6 L 4.12 20 L 5.5 20 L 8.22 13.44 L 7.84 12.54 L 5.02 19.3 Z'
            fill={lightColor}
          />
          <Path
            d='M 12.86 13.92 L 9.6 6.38 L 8.82 6.38 L 12.98 15.98 L 15.66 7.24 L 14.9 7.24 L 12.86 13.92 Z'
            fill={lightColor}
          />
          <Path
            fill={lightColor}
            d='M 12.74 12.18 L 14.42 6.7 L 16.46 6.7 L 12.812 18.794 L 13.2 19.84 L 17.42 6 L 13.88 6 L 12.32 11.18 L 12.74 12.18 Z'
          />
          <Path
            d='M 13.508 5.5 L 18.095 5.5 L 13.282 21.287 L 11.871 18.03 L 11.278 17.922 L 11.598 17.397 L 7.9 8.859 L 5.756 13.813 L 4.989 11.27 L 7.311 5.88 L 8.482 5.88 L 13.081 16.028 L 15.784 7.2 L 14.79 7.2 L 12.813 13.646 L 11.789 11.208 Z M 13.158 18.262 L 13.053 18.243 L 13.118 18.393 Z M 8.359 7.401 L 8.074 7.401 L 12.174 16.451 L 12.235 16.352 Z M 7.744 7.401 L 7.441 7.401 L 5.924 10.907 L 6.051 11.33 Z M 8.074 7.401 L 7.905 7.029 L 7.744 7.401 Z'
            fill='none'
          />
          <Path
            d='M 13.508 5.5 L 18.095 5.5 L 13.282 21.287 L 7.9 8.859 L 5.756 13.812 L 4.989 11.27 L 7.311 5.88 L 8.482 5.88 L 13.081 16.028 L 15.784 7.2 L 14.79 7.2 L 12.813 13.646 L 11.789 11.208 Z M 12.851 11.152 L 14.252 6.5 L 16.745 6.5 L 13.118 18.393 L 8.359 7.401 L 8.074 7.401 L 12.218 16.546 L 12.776 18.137 L 13.348 18.574 L 17.136 6.2 L 14.05 6.2 L 12.667 10.714 Z M 7.744 7.401 L 7.441 7.401 L 5.924 10.907 L 6.051 11.33 Z'
            fill='none'
          />
          <Path
            d='M 13.508 5.5 L 18.095 5.5 L 13.282 21.287 L 7.9 8.859 L 5.756 13.812 L 4.989 11.27 L 7.311 5.88 L 8.482 5.88 L 13.081 16.028 L 15.784 7.2 L 14.79 7.2 L 12.813 13.646 L 11.789 11.208 Z M 12.851 11.152 L 14.252 6.5 L 16.745 6.5 L 13.118 18.393 L 8.359 7.401 L 8.074 7.401 L 12.218 16.546 L 12.776 18.137 L 13.348 18.574 L 17.136 6.2 L 14.05 6.2 L 12.667 10.714 Z M 7.744 7.401 L 7.441 7.401 L 5.924 10.907 L 6.051 11.33 Z'
            fill='none'
          />
          <Path
            d='M 2.58 6 L 5.68 16.2 L 6.065 15.013 L 3.34 6 L 2.58 6 Z'
            fill={lightColor}
          />
          <Path
            fill={darkColor}
            d='M 12.378 17.854 L 12.647 16.783 L 8.172 6.382 L 7.652 6.382 L 5.603 11.18 L 5.933 12.19 L 7.912 7.602 L 12.378 17.854 Z'
          />
          <Path
            d='M 6.196 13.033 L 6.515 14.129 L 7.896 10.961 L 11.856 20.041 L 12.636 20.041 L 7.896 9.141 L 6.196 13.033 Z'
            fill={darkColor}
          />
        </G>
      </Svg>
    </View>
  );
};
