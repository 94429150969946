import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    userId: null,
    name: null,
    email: null,
    role: null,
    tokenValidTo: '',
  },
  authLoading: true,
  onlineState: true,
  authError: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onlineState(state, action) {
      state.onlineState = action.payload;
    },
    authenticate(state, action) {
      state.user = action.payload.user;
    },
    loading(state, action) {
      state.authLoading = action.payload;
    },
    logout: (state) => {
      state.user = initialState.user;
      state.authError = undefined;
    },
    setError(state, acton) {
      state.authError = acton.payload;
    },
    errorConfirmation(state) {
      state.authError = undefined;
    },
  },
});

export const authActions = authSlice.actions;
