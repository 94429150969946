import { useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { ActivityIndicator, FlatList, StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';

import { View } from 'react-native';
import { CustomButton } from '../../../components/CustomButton';
import { appleColors, selectedColors } from '../../../constants/Colors';

import { MovieElement } from './MovieElement';

import { getMovies } from '../../../store/MediaCloud/mediaCloud-actions';
import { mediaCloudActions } from '../../../store/MediaCloud/mediaCloud-slice';
import { Layout } from '../../../constants/Layout';

/* let MoviesScreenRenders = {
  renderScreen: 0,
  renderList: 0,
  listItem: 0,
  renderListItem: 0,
}; */
export default function MusicScreen() {
  //MoviesScreenRenders.renderScreen += 1;
  //console.log({ MoviesScreenRenders });
  const dispatch = useDispatch();

  const movieData = useSelector(
    (state: RootState) => state.mediaCloud.moviesData
  );
  const loading = useSelector(
    (state: RootState) => state.mediaCloud.moviesDataLoading
  );
  const error = useSelector(
    (state: RootState) => state.mediaCloud.moviesDataError
  );

  const loadMovies = useCallback(() => {
    dispatch(getMovies());
  }, []);

  useEffect(() => {
    loadMovies();
  }, []);

  const movieWidth = 300;
  const movieMarginHorizontal = 20;
  const numberOfColumnsWithoutMargin = Math.floor(
    Layout.window.width / movieWidth
  );
  const numberOfListColumns = Math.floor(
    (Layout.window.width -
      numberOfColumnsWithoutMargin * movieMarginHorizontal) /
      movieWidth
  );
  const flatlistMarginHorizontal = Math.floor(
    (Layout.window.width -
      numberOfListColumns * movieWidth -
      numberOfListColumns * movieMarginHorizontal) /
      4
  );

  const RenderList = useCallback(() => {
    //MoviesScreenRenders.renderList += 1;
    //MoviesScreenRenders.listItem = movieData.length;
    //console.log({ MoviesScreenRenders });
    return (
      <FlatList
        style={{
          paddingHorizontal: flatlistMarginHorizontal,
        }}
        numColumns={numberOfListColumns}
        onRefresh={() => {
          loadMovies();
        }}
        refreshing={loading}
        data={movieData.slice().sort((a, b) => a.date < b.date)}
        keyExtractor={(item) => item._id}
        renderItem={renderItem}
        getItemLayout={(data, index) => ({
          length: 1,
          offset: 1 * index,
          index,
        })}
        initialNumToRender={10}
      />
    );
  }, [movieData, loading]);

  const renderItem = ({ item }) => {
    //MoviesScreenRenders.renderListItem += 1;
    //console.log({ MoviesScreenRenders });
    return (
      <MovieElement
        item={item}
        movieWidth={movieWidth}
        movieMarginHorizontal={movieMarginHorizontal}
      />
    );
  };

  return (
    <Fragment>
      <View style={styles.librariesContainer}>
        <View style={styles.moviesContainer}>
          {loading ? (
            <ActivityIndicator size='large' />
          ) : error === 'No movie found!' ? (
            <CustomButton
              title={'No movie found!'}
              titleObject={
                loading ? <ActivityIndicator size='small' /> : undefined
              }
              disabled={loading ? true : false}
              onPress={loadMovies}
              textStyle={{
                fontSize: 18,
                color: selectedColors.White,
                fontFamily: 'sf-pro',
              }}
              button={{
                marginVertical: 20,
                height: 50,
                width: 200,
                color: appleColors.Blue.Dark,
                borderRadius: 10,
              }}
            />
          ) : (
            <RenderList />
          )}
        </View>
      </View>
      <AwesomeAlert
        show={error && error !== 'No movie found!' ? true : false}
        title='An Error Occurred!'
        message={error ? error : ''}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        //cancelText='No, cancel'
        confirmText='Ok'
        confirmButtonColor='#DD6B55'
        //onCancelPressed={}
        onConfirmPressed={() => {
          dispatch(mediaCloudActions.movieErrorConfirmation());
        }}
      />
    </Fragment>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  librariesContainer: {
    flex: 1,
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  moviesContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
});
