import { View } from 'react-native';
import { selectedColors } from '../../../constants/Colors';
import { Layout } from '../../../constants/Layout';

export default function CircleDecoration() {
  return (
    <View
      style={{
        width: 6,
        height: 6,
        borderColor: selectedColors.Black,
        borderWidth: 1,
        borderRadius: 3,
        marginHorizontal: Layout.isMobileDevice ? 5 : 12,
      }}
    />
  );
}
