import { mediaCloudActions } from '../mediaCloud-slice';
import { AppDispatch } from '../../index';
import { httpRequest } from '../../../utils/httpRequest';

import {
  backendUrl,
  backendGraphqlUrlPostfix,
  backendUploadUrlPostfix,
} from 'react-native-dotenv';

export const uploadAudioTrack = (trackData: any[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(mediaCloudActions.uploadingTrack(true));

    try {
      const formData = new FormData();
      for (const track of trackData) {
        formData.append('tracks', track.dataBlob, track.name);
      }
      const { trackInfo } = await httpRequest({
        url: `${backendUrl + backendUploadUrlPostfix}`,
        method: 'PUT',
        body: formData,
      });

      let graphqlQuery: { query: string; variables?: any } = {
        query: `
          mutation createNewTrack($name: String!, $mediaFile: String!, $discNumber: Int!, $trackNumber: Int!, $duration: Float!, $artist: [ArtistInput]!, $composer: [ArtistInput], $album: AlbumInput!) {
            createTrack(
                trackInput: {
                  name: $name
                  mediaFile: $mediaFile
                  discNumber: $discNumber,
                  trackNumber: $trackNumber,
                  duration: $duration,
                  artist: $artist,
                  composer: $composer,
                  album: $album
                }
            ) {
              __typename
              ... on TrackData 
                  {
                  _id,
                  name,
                  slug,
                  mediaFile,
                  discNumber,
                  trackNumber,
                  duration,
                      artist {
                        _id,
                        name,
                        images,
                        slug,
                        date
                      },
                      composer {
                        _id,
                        name,
                        images,
                        slug,
                        date
                      },
                      album {
                        _id,
                        name,
                        slug,
                        coverImage,
                        images,
                        releaseDate,
                        totalDiscs,
                        date
                      }
                      date
                  }
              ... on Error {
                  message
              }
            }
          }
      `,
      };

      for (const trackMeta of trackInfo) {
        graphqlQuery.variables = trackMeta;

        await httpRequest({
          url: `${backendUrl + backendGraphqlUrlPostfix}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: graphqlQuery,
        });
      }

      dispatch(mediaCloudActions.uploadingTrack(false));
    } catch (err) {
      dispatch(mediaCloudActions.uploadingTrack(false));
      dispatch(mediaCloudActions.setUploadingTrackError(err.message));
    }
  };
};
