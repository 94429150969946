import { Fragment } from 'react';
import {
  ImageBackground,
  ScrollView,
  Text,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import { CustomButton } from '../../components/CustomButton';

import ResourcesScreen from './Resources/ResourcesScreen';
import FooterScreen from './Footer/FooterScreen';

import { Layout } from '../../constants/Layout';
import { appleColors, selectedColors } from '../../constants/Colors';

import {
  backendUrl,
  backendPublicFileStorageUrlPostfix,
} from 'react-native-dotenv';

export default function HomeScreen({ navigation }: any) {
  return (
    <Fragment>
      <ScrollView style={[styles.container, styles.overallMargin]}>
        {Layout.isMobileDevice ? undefined : (
          <View style={styles.logosContainer}></View>
        )}
        <View style={styles.quoteContainer}>
          <View style={styles.quoteTextContainer}>
            <Text style={[styles.text, styles.largeText]}>
              ”SIMPLICITY IS THE ULTIMATE SOPHISTICATION.”
            </Text>
            <Text style={[styles.text, styles.smallText]}>
              - LEONARDO DA VINCI
            </Text>
          </View>
          <CustomButton
            title={'Discover more about me'.toLocaleUpperCase()}
            onPress={() => navigation.navigate('CV')}
            textStyle={{
              fontSize: fontSizes.small,
              color: appleColors.Gray_2.Dark,
              fontFamily: 'sf-pro',
            }}
            button={{
              height: Layout.isMobileDevice ? 35 : 50,
              width: Layout.isMobileDevice ? 180 : 270,
              color: selectedColors.White,
              borderRadius: 25,
              marginVertical: 25,
            }}
          />
          {Layout.isMobileDevice ? undefined : (
            <TouchableWithoutFeedback
              onPress={() => navigation.navigate('Travel')}
            >
              <Image
                style={styles.travelLogo}
                source={{
                  uri:
                    backendUrl +
                    backendPublicFileStorageUrlPostfix +
                    '/introduction/mapbox/travel_icon.jpg',
                }}
              />
            </TouchableWithoutFeedback>
          )}
        </View>
        <ResourcesScreen />
        <FooterScreen />
      </ScrollView>
      <ImageBackground
        source={{
          uri: `${
            backendUrl + backendPublicFileStorageUrlPostfix
          }/introduction/images/cover${
            Layout.isMobileDevice ? '_mobile' : ''
          }.jpg`,
        }}
        style={[styles.image, styles.overallMargin]}
      />
    </Fragment>
  );
}

const borderOn = false;
const fontSizes = {
  small: Layout.isMobileDevice ? 10 : 16,
  medium: Layout.isMobileDevice ? 12 : 18,
  large: Layout.isMobileDevice ? 24 : 40,
};

const styles = StyleSheet.create({
  overallMargin: {
    marginHorizontal: '1%',
    marginTop: '1%',
  },
  container: {
    backgroundColor: '#00000066',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'red' : undefined,
  },
  logosContainer: {
    height: 170,
    margin: 30,
    alignItems: 'flex-end',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
  },
  quoteContainer: {
    height: Layout.isMobileDevice ? 730 : 700,
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  quoteTextContainer: {
    paddingTop: Layout.isMobileDevice ? 20 : 150,
    alignItems: 'center',
    width: Layout.isMobileDevice ? '100%' : '40%',
  },
  text: {
    color: 'white',
    fontFamily: 'sf-pro',
    fontWeight: '200',
  },
  largeText: {
    width: Layout.isMobileDevice ? '90%' : '75%',
    fontSize: fontSizes.large,
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'yellow' : undefined,
  },
  smallText: {
    width: Layout.isMobileDevice ? '90%' : '75%',
    fontSize: fontSizes.medium,
    lineHeight: Layout.isMobileDevice ? 25 : 50,
    letterSpacing: Layout.isMobileDevice ? 6 : 12,
    textAlign: 'right',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'yellow' : undefined,
  },
  image: {
    height: Layout.window.height,
    width: '99%',
    borderWidth: borderOn ? 5 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
    position: 'absolute',
    zIndex: -1,
  },
  travelLogo: {
    width: Layout.isMobileDevice ? 307 * 0.5 : 307,
    height: Layout.isMobileDevice ? 192 * 0.5 : 192,
    marginBottom: 20,
    cursor: 'pointer',
    position: 'absolute',
    right: Layout.isMobileDevice ? '7%' : '3%',
    bottom: Layout.isMobileDevice ? '2%' : '4%',
  },
});
