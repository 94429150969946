import * as React from 'react';

import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { appleColors } from '../constants/Colors';

type CustomButtonProps = {
  title?: string;
  titleObject?: object;
  turnOnFloat?: boolean;
  disabled?: boolean;
  disabledColor?: string;
  onPress(): void;
  button: {
    height?: number | string;
    width?: number | string;
    color: string;
    marginHorizontal?: number;
    marginVertical?: number;
    borderWidth?: number;
    borderRadius?: number;
    borderTopLeftRadius?: number;
    borderTopRightRadius?: number;
    borderBottomLeftRadius?: number;
    borderBottomRightRadius?: number;
  };
  position?: {
    top?: string | number;
    left?: string | number;
    right?: string | number;
    bottom?: string | number;
  };
  textStyle?: Text['props']['style'];
};

export function CustomButton(props: CustomButtonProps) {
  return (
    <TouchableWithoutFeedback
      onPress={props.onPress}
      disabled={props.disabled ? true : false}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          position: props.turnOnFloat ? 'absolute' : undefined,
          top: props.position?.top ? props.position?.top : undefined,
          left: props.position?.left ? props.position?.left : undefined,
          right: props.position?.right ? props.position?.right : undefined,
          bottom: props.position?.bottom ? props.position?.bottom : undefined,
          marginHorizontal: props.button?.marginHorizontal
            ? props.button?.marginHorizontal
            : undefined,
          marginVertical: props.button?.marginVertical
            ? props.button?.marginVertical
            : undefined,
          height: props.button.height ? props.button.height : undefined,
          width: props.button.width ? props.button.width : undefined,
          borderWidth: props.button?.borderWidth
            ? props.button.borderWidth
            : undefined,
          borderRadius: props.button?.borderRadius
            ? props.button?.borderRadius
            : undefined,
          borderTopLeftRadius: props.button?.borderTopLeftRadius
            ? props.button?.borderTopLeftRadius
            : undefined,
          borderTopRightRadius: props.button?.borderTopRightRadius
            ? props.button?.borderTopRightRadius
            : undefined,
          borderBottomLeftRadius: props.button?.borderBottomLeftRadius
            ? props.button?.borderBottomLeftRadius
            : undefined,
          borderBottomRightRadius: props.button?.borderBottomRightRadius
            ? props.button?.borderBottomRightRadius
            : undefined,
          backgroundColor: props.disabled
            ? props.disabledColor
              ? props.disabledColor
              : appleColors.Gray_4.Light
            : props.button.color,
        }}
      >
        {props.titleObject ? (
          props.titleObject
        ) : (
          <Text style={props.textStyle}>{`${
            props?.title ? props.title : ''
          }`}</Text>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}
