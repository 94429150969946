import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from '../screens/Home/HomeScreen';
import CVScreen from '../screens/Home/CV/CVScreen';
import TravelScreen from '../screens/Home/Travel/TravelScreen';
import { Wolftech_logo_only } from '../components/Wolftech_logo_only';
import { AuthTokenTime } from '../components/AuthTokenTime';
import { appleColors, selectedColors } from '../constants/Colors';
import { Layout } from '../constants/Layout';

export default function HomeNavigator() {
  const HomeStack = createStackNavigator();

  let headerStyle = {
    backgroundColor: appleColors.Gray_5.Dark,
  };
  if (Layout.isMobileDevice) {
    headerStyle.height = 40;
  }

  return (
    <HomeStack.Navigator
      initialRouteName='HomeStack'
      screenOptions={{
        headerTitle: (props) => (
          <Wolftech_logo_only
            title={props.children}
            size={Layout.isMobileDevice ? 'small' : 'large'}
          />
        ),
        headerRight: () => <AuthTokenTime />,
        headerStyle,
        animationEnabled: true,
        headerTintColor: selectedColors.White,
        presentation: 'card',
        //headerShown: false,
      }}
    >
      <HomeStack.Screen name='Home' component={HomeScreen} />
      <HomeStack.Screen name='CV' component={CVScreen} />
      <HomeStack.Screen name='Travel' component={TravelScreen} />
    </HomeStack.Navigator>
  );
}
