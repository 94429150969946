import { Platform } from 'react-native';
import { Audio, AVPlaybackStatus } from 'expo-av';

Audio.setAudioModeAsync({
  playsInSilentModeIOS: true,
  allowsRecordingIOS: false,
  interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
  interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  shouldDuckAndroid: true,
  playThroughEarpieceAndroid: true,
  staysActiveInBackground: true,
});

export const audioInstance = new Audio.Sound();

type VideoFullscreenUpdateEvent = {
  fullscreenUpdate: 0 | 1 | 2 | 3;
  status?: AVPlaybackStatus;
};

export const onFullscreenUpdate = async ({
  fullscreenUpdate,
}: VideoFullscreenUpdateEvent) => {
  if (Platform.OS === 'android') {
  }
};
