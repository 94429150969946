export const customArrayFunctions = {
  searchInArrayOfObjects: (
    array: Array<any>,
    identifierField: string,
    searchValue: string
  ) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][`${identifierField}`] === searchValue) {
        return array.indexOf(array[i]);
      }
    }
    return -1;
  },
  getArraysUnion: (array1: Array<any>, array2: Array<any>) => {
    var ret = [];
    array1.sort();
    array2.sort();
    for (var i = 0; i < array1.length; i += 1) {
      if (array2.indexOf(array1[i]) > -1) {
        ret.push(array1[i]);
      }
    }
    return ret;
  },
  getArraysDiff: (array: Array<any>, arrayMinus: Array<any>) => {
    var ret = [];
    array.sort();
    arrayMinus.sort();
    for (var i = 0; i < array.length; i += 1) {
      if (arrayMinus.indexOf(array[i]) === -1) {
        ret.push(array[i]);
      }
    }
    return ret;
  },
  getOnlyUnique: (array: Array<any>) => {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  },
  regroupArrayByDate: (
    array: Array<any>,
    dateField: string,
    regroupBy: 'year' | 'month' | 'day',
    sort: 'desc' | 'asc'
  ) => {
    const groups = array.reduce((array, element) => {
      let regrouper;
      if (regroupBy === 'day') {
        regrouper = element[`${dateField}`].split('T')[0];
      } else if (regroupBy === 'month') {
        regrouper = `${element[`${dateField}`].split('-')[0]}-${
          element[`${dateField}`].split('-')[1]
        }-01`;
      } else if (regroupBy === 'year') {
        regrouper = element[`${dateField}`].split('-')[0];
      }
      if (!array[regrouper]) {
        array[regrouper] = [];
      }
      array[regrouper].push(element);
      return array;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        data: groups[date],
      };
    });

    groupArrays.sort((a, b) =>
      a.date > b.date
        ? sort === 'asc'
          ? -1
          : 1
        : b.date > a.date
        ? sort === 'asc'
          ? 1
          : -1
        : 0
    );

    return groupArrays;
  },
  regroupArrayByData: (array: Array<any>, customData: string) => {
    const groups = array.reduce((array, element) => {
      let regrouper = element[`${customData}`];
      if (!array[regrouper]) {
        array[regrouper] = [];
      }
      array[regrouper].push(element);
      return array;
    }, {});

    const groupArrays = Object.keys(groups).map((data) => {
      return {
        [`${customData}`]: data,
        data: groups[data],
      };
    });

    groupArrays.sort((a, b) =>
      Number.isInteger(+a[customData])
        ? parseInt(a[customData]).toLocaleString('en-US', {
            minimumIntegerDigits: 10,
          }) >
          parseInt(b[customData]).toLocaleString('en-US', {
            minimumIntegerDigits: 10,
          })
        : a[customData].localeCompare(b[customData])
    );

    return groupArrays;
  },
  regroupArrayOfObjects: (array: Array<any>, customRegruper?: string) => {
    const groups = array.reduce((array, element) => {
      let regrouper = customRegruper
        ? element[Object.keys(element)[0]][customRegruper][0]
        : Object.keys(element)[0][0];

      if (!array[regrouper]) {
        array[regrouper] = [];
      }
      array[regrouper].push(element);

      return array;
    }, {});

    const groupArrays = Object.keys(groups).map((alphabet) => {
      return {
        alphabet,
        data: groups[alphabet],
      };
    });

    groupArrays.sort((a, b) => a.alphabet.localeCompare(b.alphabet));
    groupArrays.forEach((alphabetArray) =>
      alphabetArray.data.sort((a: any, b: any) =>
        Object.keys(a)[0].localeCompare(Object.keys(b)[0])
      )
    );

    return groupArrays;
  },
};
