import React, { useState, useEffect } from 'react';
import { Platform, Alert, View } from 'react-native';

import { audioInstance } from './Configuration';
import { AVPlaybackStatus } from 'expo-av';

import { BottomAudioPlayer } from './Audio/BottomAudioPlayer';
import { VideoPlayer } from './Video/VideoPlayer';

type MediaPlayerProps = {
  style?: object;
  playPrevious?(): void;
  playNext?(): void;
  audioMedia?: {
    disc: string;
    track: string;
    title: string;
    mediaFile: string;
  };
  videoMedia?: {
    title: string;
    mediaFile: string;
    posterImage: string;
  };
  volume?: number;
};

/* let MediaPlayerRenders = {
  renderScreen: 0,
}; */
export const MediaPlayer = (props: MediaPlayerProps) => {
  //MediaPlayerRenders.renderScreen += 1;
  //console.log({ MediaPlayerRenders });

  const { style, audioMedia, videoMedia, playPrevious, playNext, volume } =
    props;
  const [isPlaying, setIsPlaying] = useState(Boolean);
  const [manualSeeking, setManualSeeking] = useState(Number);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (audioMedia?.mediaFile) {
      (async (mediaFile) => {
        if (
          Platform.OS === 'android' &&
          mediaFile.substr(mediaFile.lastIndexOf('.') + 1) === 'flac'
        ) {
          Alert.alert(
            'Unsupported media format!',
            'Flac playback not supported natively on Android devices.',
            [
              {
                text: 'Ok',
                onPress: () => {},
              },
            ]
          );
        } else {
          try {
            const source = {
              uri: encodeURI(mediaFile),
            };

            await audioInstance.loadAsync(
              source,
              {
                shouldPlay: isPlaying ? true : false,
                progressUpdateIntervalMillis: 1000,
                seekMillisToleranceBefore: 0,
                seekMillisToleranceAfter: 0,
                volume,
              },
              true
            );
          } catch (e) {
            console.log(e);
          }
        }
      })(audioMedia.mediaFile);
    }

    audioInstance.setOnPlaybackStatusUpdate(
      (playbackStatus: AVPlaybackStatus) => {
        if (isMounted) {
          setIsLoaded(playbackStatus.isLoaded);
          if (playbackStatus.didJustFinish) {
            playNext ? playNext() : undefined;
          }
        }
      }
    );

    return audioInstance
      ? () => {
          isMounted = false;
          audioInstance.unloadAsync();
        }
      : () => {
          isMounted = false;
        };
  }, [audioInstance, audioMedia]);

  return audioMedia ? (
    <BottomAudioPlayer
      configuration={{
        style,
        playbackStatus: {
          isLoaded,
        },
        volume,
        audioMedia,
        audioInstance,
        playingHandler: { setIsPlaying, isPlaying },
        playPrevious,
        playNext,
        manualSeekingHandler: { setManualSeeking, manualSeeking },
      }}
    />
  ) : videoMedia ? (
    <VideoPlayer
      configuration={{
        style,
        videoMedia,
      }}
    />
  ) : (
    <View />
  );
};
