import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { TouchableWithoutFeedback, View, Text, StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import { Hoverable } from 'react-native-web-hover';

import { appleColors, selectedColors } from '../../constants/Colors';
import { Layout } from '../../constants/Layout';

export default function MediaCloudScreen({ navigation }: any) {
  const user = useSelector((state: RootState) => state.auth.user);

  const Service = (props: { name: string; nav: string; icon: string }) => {
    return (
      <Hoverable>
        {({ hovered }) => (
          <TouchableWithoutFeedback
            onPress={() => navigation.navigate(props.nav)}
          >
            <View
              style={[
                styles.serviceButton,
                {
                  height: Layout.desktop ? 300 : 250,
                  width: Layout.desktop ? 300 : 250,
                  borderRadius: Layout.desktop ? 20 : 15,
                  marginVertical: Layout.desktop ? 0 : 15,
                  backgroundColor: hovered
                    ? appleColors.Blue.Light
                    : appleColors.Blue.Dark,
                },
              ]}
            >
              <Icon
                name={props.icon}
                type='ionicon'
                size={Layout.desktop ? 100 : 50}
                color={hovered ? selectedColors.White : appleColors.Gray_3.Dark}
              />
              <Text
                style={[
                  styles.serviceButtonText,
                  {
                    fontSize: Layout.desktop ? 30 : 25,
                    color: hovered
                      ? selectedColors.White
                      : appleColors.Gray_3.Dark,
                  },
                ]}
              >
                {props.name}
              </Text>
            </View>
          </TouchableWithoutFeedback>
        )}
      </Hoverable>
    );
  };

  return (
    <View style={styles.container}>
      <View style={[
          styles.serviceContainer,
          {flexDirection: Layout.desktop ? 'row' : 'column'}
          ]}>
        <Service name={'Music'} nav={'MusicStack'} icon={'musical-notes'} />
        <Service name={'Movie'} nav={'MovieStack'} icon={'film'} />
      </View>
    </View>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-evenly',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  serviceContainer: {
    alignItems:'center',
    justifyContent: 'space-evenly',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'red' : undefined,
  },
  serviceButton: {
    cursor: 'pointer',
    justifyContent: 'space-evenly',
  },
  serviceButtonText: {
    marginTop: -70,
    textAlign: 'center',
    fontFamily: 'sf-pro-bold'
  },
});
