import React from 'react';
import { Provider } from 'react-redux';

import store from './store/index';
import { useCachedResources } from './hooks/useCachedResources';

import Navigation from './navigation/index';

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <Navigation />
      </Provider>
    );
  }
}
