import * as React from 'react';

import { View as DefaultView } from 'react-native';
import { appleColors } from '../constants/Colors';

export const Separator = (props: DefaultView['props']) => {
  const { style, ...otherProps } = props;

  return (
    <DefaultView style={[{ paddingLeft: 20 }, style]}>
      <DefaultView
        style={[
          {
            backgroundColor: appleColors.Gray_2.Dark,
            height: 1,
            width: '100%',
          },
          style,
        ]}
        {...otherProps}
      />
    </DefaultView>
  );
};
