import { customArrayFunctions } from './customArrayFunctions';

export enum formValidatorReducerActions {
  InputUpdate = 'FORM_INPUT_UPDATE',
}

type State = {
  inputValues: any;
  inputValidities: any;
  formIsValid: boolean;
  formFieldCompareIsValid?: boolean;
};

type Action = {
  type: formValidatorReducerActions;
  payload: {
    input: string;
    isValid?: boolean;
    value: string;
    compare?: Array<string>;
  };
};

export const formValidatorReducer = (state: State, action: Action) => {
  const { type, payload } = action;

  let updatedValues = {
    ...state.inputValues,
    [payload.input]: payload.value,
  };
  let updatedValidities = {
    ...state.inputValidities,
  };
  let updatedFormIsValid = state.formIsValid;
  let updatedFormFieldCompareIsValid = state.formFieldCompareIsValid;
  switch (type) {
    case formValidatorReducerActions.InputUpdate:
      updatedValidities = {
        ...updatedValidities,
        [payload.input]: payload.isValid,
      };

      updatedFormIsValid = !Object.values(updatedValidities).includes(false);

      if (payload.compare) {
        updatedFormFieldCompareIsValid = false;
        let comparableValues = [];
        for (const key in updatedValues) {
          if (
            payload.compare
              ? payload.compare.filter((el) => el === key).length > 0
              : undefined
          ) {
            comparableValues.push(updatedValues[key]);
          }
        }
        const unique = customArrayFunctions.getOnlyUnique(comparableValues);
        if (unique.length === 1) {
          updatedFormFieldCompareIsValid = true;
        }

        updatedFormIsValid =
          !Object.values(updatedValidities).includes(false) &&
          updatedFormFieldCompareIsValid;

        return {
          formIsValid: updatedFormIsValid,
          formFieldCompareIsValid: updatedFormFieldCompareIsValid,
          inputValidities: updatedValidities,
          inputValues: updatedValues,
        };
      }

      return {
        formIsValid: updatedFormIsValid,
        inputValidities: updatedValidities,
        inputValues: updatedValues,
      };
    default:
      return state;
  }
};
