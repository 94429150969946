import { useLinkTo } from '@react-navigation/native';
import {
  TouchableWithoutFeedback,
  View,
  Text,
  Image,
  Platform,
  StyleSheet,
} from 'react-native';
import { Icon } from 'react-native-elements';

import { getImageSet } from '../../../utils/getImageSet';

export const MovieElement = ({
  item,
  movieWidth,
  movieMarginHorizontal,
}: any) => {
  const linkTo = useLinkTo();

  const openMovie = () => {
    linkTo(`/mediacloud/movie/cinema/${item.IMDbTitleId}`);
  };

  const cast = item.IMDbData.cast.map((cast, index) => {
    return index < 3 ? (index > 0 ? `, ${cast.actor_name}` : cast.actor_name) : undefined;
  });

  const coverImageSource = getImageSet(item.IMDbData.poster);

  return (
    <TouchableWithoutFeedback onPress={openMovie}>
      <View
        style={[
          styles.movie,
          {
            width: movieWidth,
            height: movieWidth * 2,
            marginHorizontal: movieMarginHorizontal / 2,
          },
        ]}
      >
        <View style={styles.moviePoster}>
          <Image
            style={styles.moviePosterImage}
            source={coverImageSource[2]}
            resizeMode='cover'
          />
        </View>
        <View style={styles.movieTitleAndCast}>
          <Text style={styles.movieTitleText}>{item.IMDbData.title}</Text>
          <Text style={styles.movieCastText}>{cast}</Text>
        </View>
        <View style={styles.movieDetails}>
          <View style={styles.movieDetailsRow}>
            <View style={styles.movieDetailsYear}>
              <Icon
                name={Platform.OS === 'android' ? 'event' : 'calendar'}
                type={Platform.OS === 'android' ? 'material' : 'ionicon'}
                size={35}
              />
              <Text>{item.IMDbData.year}</Text>
            </View>
            <View style={styles.movieDetailsRuntime}>
              <Icon
                name={Platform.OS === 'android' ? 'schedule' : 'time-outline'}
                type={Platform.OS === 'android' ? 'material' : 'ionicon'}
                size={35}
              />
              <Text>{item.IMDbData.runtime}</Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const borderOn = false;
const borderRadius = 5;
const fontFamily = 'sf-pro';

const styles = StyleSheet.create({
  movie: {
    cursor: 'pointer',
    marginVertical: 20,
    borderRadius: borderRadius,
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 1 : undefined,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.3,
    shadowRadius: 9,
  },
  moviePoster: {
    height: '75%',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 1 : undefined,
  },
  moviePosterImage: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
  },
  movieTitleAndCast: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '10%',
    paddingHorizontal: '5%',
    borderColor: borderOn ? 'green' : undefined,
    borderWidth: borderOn ? 1 : undefined,
  },
  movieTitleText: {
    fontFamily: 'sf-pro-bold',
    fontSize: 18,
  },
  movieCastText: {
    fontFamily,
    fontSize: 16,
  },
  movieDetails: {
    justifyContent: 'space-evenly',
    height: '15%',
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 1 : undefined,
  },
  movieDetailsRow: {
    flexDirection: 'row',
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  },
  movieDetailsYear: {
    alignItems: 'center',
    width: '50%',
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 1 : undefined,
  },
  movieDetailsRuntime: {
    alignItems: 'center',
    width: '50%',
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 1 : undefined,
  },
});
