import { View, Text } from 'react-native';
import { Icon } from 'react-native-elements';

type PlayingDiscProps = {
  audioMedia: {
    totalDiscs?: string;
    disc?: string;
  };
};

export const PlayingDisc = (props: PlayingDiscProps) => {
  const { audioMedia } = props;

  return audioMedia.totalDiscs && +audioMedia.totalDiscs > 1 ? (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        width: '12%',
      }}
    >
      <Icon name={'disc'} type={'ionicon'} size={20} />
      <Text
        style={{
          marginLeft: 0,
          fontSize: 14,
        }}
      >
        {audioMedia?.disc}
      </Text>
    </View>
  ) : null;
};
