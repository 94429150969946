import { AppDispatch } from '..';
import { introductionActions } from './introduction-slice';
import { httpRequest } from '../../utils/httpRequest';

import { backendUrl, backendGraphqlUrlPostfix } from 'react-native-dotenv';

export const getResource = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(introductionActions.resourceLoading(true));

    try {
      const graphqlQuery = {
        query: `
            query {
                getResource {
                    __typename
                    ... on ResourceData {
                        resourceId
                        resourceType
                        resourceItems {
                            resourceItemId
                            name
                            description
                            logo
                            link
                        }
                    }
                    ... on Error {
                        message
                    }
                }
            }
        `,
      };

      const responseData = await httpRequest({
        url: backendUrl + backendGraphqlUrlPostfix,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.getResource[0].__typename === 'Error') {
        return dispatch(
          introductionActions.setError(responseData.getResource[0].message)
        );
      } else {
        dispatch(introductionActions.setResourceData(responseData.getResource));
      }
      dispatch(introductionActions.resourceLoading(false));
    } catch (err) {
      dispatch(introductionActions.resourceLoading(false));
      return dispatch(introductionActions.setError(err));
    }
  };
};

export const getCV = (language: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(introductionActions.CVLoading(true));

    try {
      const graphqlQuery = {
        query: `
          query GetCV($language: String!){
            getCV ( language: $language ) {
                __typename
                ... on cvData {
                    cvId
                    language
                    personName
                    dateOfBirth
                    placeOfBirth
                    nationality
                    address
                    phoneNumber
                    email
                    drivingLicence
                    hobbies
                    languages {
                            languageId
                            name
                            knowledgementPercentage
                        }
                    employmentHistory {
                            employmentHistoryId
                            jobTitle
                            description
                            durationOfEmployment
                        }
                    education {
                        educationId
                        institution
                        description
                        durationOfStudy
                        degree
                        }
                    courses {
                            courseId
                            name
                            description
                            durationOfStudy
                            certificateLink
                        }
                    skills {
                            skillId
                            name
                            knowledgementPercentage
                        }
                }
                ... on Error {
                    message
                }
            }
        }
        `,
        variables: {
          language,
        },
      };

      const responseData = await httpRequest({
        url: backendUrl + backendGraphqlUrlPostfix,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.getCV[0].__typename === 'Error') {
        return dispatch(
          introductionActions.setError(responseData.getCV[0].message)
        );
      } else {
        dispatch(introductionActions.setCVData(responseData.getCV));
      }
      dispatch(introductionActions.CVLoading(false));
    } catch (err) {
      dispatch(introductionActions.CVLoading(false));
      return dispatch(introductionActions.setError(err));
    }
  };
};
