import { TouchableWithoutFeedback, View, Text, StyleSheet } from 'react-native';

import { appleColors, selectedColors } from '../../../constants/Colors';

export const TrackListElement = ({ item, onPress, selected }) => {
  let minutes = Math.floor(item.duration / 60);
  let seconds = ('0' + Math.floor(item.duration - minutes * 60)).slice(-2);
  const trackLength = `${minutes}:${seconds}`;

  //console.log(selected ? );

  return (
    <TouchableWithoutFeedback onPress={() => onPress()}>
      <View style={[styles.track, selected ? styles.selectedTrack : undefined]}>
        <Text
          style={selected ? styles.selectedTrackText : undefined}
        >{`${item.trackNumber.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })} - ${item.name}`}</Text>
        <Text style={selected ? styles.selectedTrackText : undefined}>
          {trackLength}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

export const TrackListHeaderElement = ({ section }) => {
  return (
    <View style={styles.discHeader}>
      <Text style={styles.discNumberText}>{`Disc ${section.discNumber}`}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  track: {
    height: 45,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  discHeader: {
    height: 30,
    marginVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: appleColors.Blue.Dark,
  },
  discNumberText: {
    color: selectedColors.White,
    fontSize: 22,
    fontFamily: 'sf-pro-bold',
  },
  selectedTrack: {
    backgroundColor: appleColors.Gray_4.Light,
    borderColor: 'red',
    borderWidth: 1,
  },
  selectedTrackText: { color: selectedColors.Black, fontFamily: 'sf-pro-bold' },
});
