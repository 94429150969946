import {
  View,
  Text,
  Image,
  Linking,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import { Hoverable } from 'react-native-web-hover';

import { appleColors, selectedColors } from '../../../constants/Colors';
import { Layout } from '../../../constants/Layout';

import {
  backendUrl,
  backendPublicFileStorageUrlPostfix,
} from 'react-native-dotenv';

export default function ResourceItem({ item }: any) {
  return (
    <View style={styles.resourceItemContainer}>
      <Hoverable>
        {({ hovered }) => (
          <TouchableWithoutFeedback
            onPress={() => {
              Linking.openURL(item.link);
            }}
          >
            <View style={styles.resourceItemButton}>
              <Image
                style={styles.resourceItemLogo}
                source={{
                  uri:
                    backendUrl +
                    backendPublicFileStorageUrlPostfix +
                    '/' +
                    item.logo,
                }}
              />
              <Text
                style={[
                  styles.resourceItemNameText,
                  {
                    color: hovered
                      ? appleColors.Blue.Dark
                      : selectedColors.Black,
                  },
                ]}
              >
                {item.name}
              </Text>
              <Text style={styles.resourceItemDescriptionText}>
                {item.description}
              </Text>
            </View>
          </TouchableWithoutFeedback>
        )}
      </Hoverable>
    </View>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  resourceItemContainer: {
    width: Layout.isMobileDevice ? '50%' : '33.333%',
    paddingHorizontal: Layout.isMobileDevice ? 10 : 40,
    paddingBottom: 20,
    alignItems: 'flex-start',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  resourceItemButton: {
    cursor: 'pointer',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
  },
  resourceItemLogo: {
    width: 40,
    height: 40,
    marginBottom: 20,
  },
  resourceItemNameText: {
    fontSize: Layout.isMobileDevice ? 14 : 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  resourceItemDescriptionText: {
    //textAlign: 'justify',
    fontSize: Layout.isMobileDevice ? 12 : 16,
    lineHeight: 28,
    fontWeight: '600',
  },
});
