import { mediaCloudActions } from '../mediaCloud-slice';
import { AppDispatch } from '../../index';
import { httpRequest } from '../../../utils/httpRequest';

import { backendUrl, backendGraphqlUrlPostfix } from 'react-native-dotenv';

export const getMovies = (IMDbTitleId?: string) => {
  return async (dispatch: AppDispatch, getState) => {
    IMDbTitleId
      ? dispatch(mediaCloudActions.movieLoading(true))
      : dispatch(mediaCloudActions.moviesLoading(true));

    if (
      IMDbTitleId &&
      getState().mediaCloud.movieDataError === 'No movie found!'
    ) {
      dispatch(mediaCloudActions.movieErrorConfirmation());
    }
    if (getState().mediaCloud.moviesDataError === 'No movie found!') {
      dispatch(mediaCloudActions.moviesErrorConfirmation());
    }

    const graphqlQuery = {
      query: `
      query GetMovie($IMDbTitleId: String) {
        getMovie (IMDbTitleId: $IMDbTitleId) {
            __typename
            ... on MovieData 
                {
                    _id
                    mediaFile,
                    IMDbTitleId,
                    IMDbData {
                        title,
                        year,
                        ageRating,
                        IMDbRating,
                        runtime,
                        poster,
                        genres,
                        plot,
                        director,
                        cast {
                            avatar,
                            actor_name,
                            character,
                        },
                        storyline,
                    },
                    date
                    }
            ... on Error {
                message
            }
        }
      }
    `,
      variables: {
        IMDbTitleId,
      },
    };

    try {
      const responseData = await httpRequest({
        url: `${backendUrl + backendGraphqlUrlPostfix}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.getMovie[0].__typename === 'Error') {
        IMDbTitleId
          ? dispatch(
              mediaCloudActions.setMovieError(responseData.getMovie[0].message)
            )
          : dispatch(
              mediaCloudActions.setMoviesError(responseData.getMovie[0].message)
            );
      } else {
        IMDbTitleId
          ? dispatch(
              mediaCloudActions.setMovie({
                movieData: responseData.getMovie,
              })
            )
          : dispatch(
              mediaCloudActions.setMovies({
                moviesData: responseData.getMovie,
              })
            );
      }
      IMDbTitleId
        ? dispatch(mediaCloudActions.movieLoading(false))
        : dispatch(mediaCloudActions.moviesLoading(false));
    } catch (err) {
      IMDbTitleId
        ? dispatch(mediaCloudActions.movieLoading(false))
        : dispatch(mediaCloudActions.moviesLoading(false));
      IMDbTitleId
        ? dispatch(mediaCloudActions.setMovieError(err.message))
        : dispatch(mediaCloudActions.setMoviesError(err.message));
    }
  };
};
