import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resourceLoading: true,
  resourceData: null,
  CVLoading: true,
  CVData: null,
  travelLoading: true,
  travelData: null,
  error: null,
};

export const introductionSlice = createSlice({
  name: 'introduction',
  initialState,
  reducers: {
    resourceLoading(state, action) {
      state.resourceLoading = action.payload;
    },
    setResourceData(state, action) {
      state.resourceData = action.payload;
    },
    CVLoading(state, action) {
      state.CVLoading = action.payload;
    },
    setCVData(state, action) {
      state.CVData = action.payload;
    },
    travelLoading(state, action) {
      state.travelLoading = action.payload;
    },
    setTravelData(state, action) {
      state.travelData = action.payload.travelData;
    },
    setError(state, acton) {
      state.error = acton.payload;
    },
    errorConfirmation(state) {
      state.error = null;
    },
  },
});

export const introductionActions = introductionSlice.actions;
