import { mediaCloudActions } from '../mediaCloud-slice';
import { AppDispatch } from '../../index';
import { httpRequest } from '../../../utils/httpRequest';

import { backendUrl, backendGraphqlUrlPostfix } from 'react-native-dotenv';

export const getAlbums = (id?: string) => {
  return async (dispatch: AppDispatch, getState) => {
    id
      ? dispatch(mediaCloudActions.albumLoading(true))
      : dispatch(mediaCloudActions.albumsLoading(true));

    if (id && getState().mediaCloud.albumDataError === 'No album found!') {
      dispatch(mediaCloudActions.albumErrorConfirmation());
    }
    if (getState().mediaCloud.albumsDataError === 'No album found!') {
      dispatch(mediaCloudActions.albumsErrorConfirmation());
    }

    const graphqlQuery = {
      query: `
        query GetAlbum($id: String) {
          getAlbum (id: $id) {
            __typename
            ... on AlbumData {
              _id,
              name,
              slug,
              coverImage,
              images,
              releaseDate,
              totalDiscs,
              artist {
                _id,
                name,
                images,
                slug
                date
              },
              composer {
                _id,
                name,
                images,
                slug
                date
              },
              tracks {
                _id,
                name,
                slug,
                mediaFile,
                discNumber,
                trackNumber,
                duration
                artist {
                  name
                }
                composer {
                  name
                }
                date
              }
              date
            }
            ... on Error {
              message
            }
          }
        }
      `,
      variables: {
        id,
      },
    };

    try {
      const responseData = await httpRequest({
        url: `${backendUrl + backendGraphqlUrlPostfix}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.getAlbum[0].__typename === 'Error') {
        id
          ? dispatch(
              mediaCloudActions.setAlbumError(responseData.getAlbum[0].message)
            )
          : dispatch(
              mediaCloudActions.setAlbumsError(responseData.getAlbum[0].message)
            );
      } else {
        id
          ? dispatch(
              mediaCloudActions.setAlbum({
                albumData: responseData.getAlbum,
              })
            )
          : dispatch(
              mediaCloudActions.setAlbums({
                albumsData: responseData.getAlbum,
              })
            );
      }
      id
        ? dispatch(mediaCloudActions.albumLoading(false))
        : dispatch(mediaCloudActions.albumsLoading(false));
    } catch (err) {
      id
        ? dispatch(mediaCloudActions.albumLoading(false))
        : dispatch(mediaCloudActions.albumsLoading(false));
      id
        ? dispatch(mediaCloudActions.setAlbumError(err.message))
        : dispatch(mediaCloudActions.setAlbumsError(err.message));
    }
  };
};
