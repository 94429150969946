import { forwardRef } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { Icon } from 'react-native-elements';

import { Layout } from '../../../../constants/Layout';

type NextControlProps = {
  playbackStatus: {
    isLoaded: Boolean;
  };
  playNext?: () => void;
};

export const NextControl = forwardRef((props: NextControlProps, ref) => {
  const { playbackStatus, playNext } = props;

  return (
    <TouchableWithoutFeedback
      disabled={playbackStatus.isLoaded ? false : true}
      style={{ width: Layout.window.width * 0.2 }}
      onPress={() => {
        playNext ? playNext() : undefined;
      }}
    >
      <View ref={ref} style={{ cursor: 'pointer' }}>
        <Icon
          name={
            playbackStatus.isLoaded ? 'play-forward' : 'play-forward-outline'
          }
          type={'ionicon'}
          size={40}
        />
      </View>
    </TouchableWithoutFeedback>
  );
});
