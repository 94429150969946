import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mediaCloudHeaderShown: true,
  albumsDataLoading: true,
  albumsData: [],
  albumsDataError: null,
  albumDataLoading: true,
  albumData: [],
  albumDataError: null,
  uploadingTrack: undefined,
  uploadingTrackError: null,
  uploadingCover: undefined,
  uploadingCoverError: null,
  moviesDataLoading: true,
  moviesData: [],
  moviesDataError: null,
  movieDataLoading: true,
  movieData: [],
  movieDataError: null,
  volume: 0.2,
};

export const mediaCloudSlice = createSlice({
  name: 'mediaCloud',
  initialState,
  reducers: {
    albumsLoading(state, action) {
      state.albumsDataLoading = action.payload;
    },
    setAlbums(state, action) {
      state.albumsData = action.payload.albumsData;
    },
    setAlbumsError(state, acton) {
      state.albumsDataError = acton.payload;
    },
    albumsErrorConfirmation(state) {
      state.albumsDataError = null;
    },
    albumLoading(state, action) {
      state.albumDataLoading = action.payload;
    },
    setAlbum(state, action) {
      state.albumData = action.payload.albumData;
    },
    setAlbumError(state, acton) {
      state.albumDataError = acton.payload;
    },
    albumErrorConfirmation(state) {
      state.albumDataError = null;
    },
    uploadingTrack(state, action) {
      state.uploadingTrack = action.payload;
    },
    setUploadingTrackError(state, acton) {
      state.uploadingTrackError = acton.payload;
    },
    uploadingTrackErrorConfirmation(state) {
      state.uploadingTrackError = null;
    },
    uploadingCover(state, action) {
      state.uploadingCover = action.payload;
    },
    setUploadingCoverError(state, acton) {
      state.uploadingCoverError = acton.payload;
    },
    uploadingCoverErrorConfirmation(state) {
      state.uploadingCoverError = null;
    },
    moviesLoading(state, action) {
      state.moviesDataLoading = action.payload;
    },
    setMovies(state, action) {
      state.moviesData = action.payload.moviesData;
    },
    setMoviesError(state, acton) {
      state.moviesDataError = acton.payload;
    },
    moviesErrorConfirmation(state) {
      state.moviesDataError = null;
    },
    movieLoading(state, action) {
      state.movieDataLoading = action.payload;
    },
    setMovie(state, action) {
      state.movieData = action.payload.movieData;
    },
    setMovieError(state, acton) {
      state.movieDataError = acton.payload;
    },
    movieErrorConfirmation(state) {
      state.movieDataError = null;
    },
    initState(state) {
      state = initialState;
    },
    mediaCloudHeaderShown(state, acton) {
      state.mediaCloudHeaderShown = acton.payload;
    },
    setVolume(state, acton) {
      state.volume = acton.payload;
    },
  },
});

export const mediaCloudActions = mediaCloudSlice.actions;

export default mediaCloudSlice;
