import { mediaCloudActions } from '../mediaCloud-slice';
import { AppDispatch } from '../../index';
import { httpRequest } from '../../../utils/httpRequest';

import {
  backendUrl,
  backendGraphqlUrlPostfix,
  backendUploadUrlPostfix,
} from 'react-native-dotenv';

export const uploadCoverImage = (albumData: {
  imageData: any;
  name: string;
  releaseDate: number;
  slug: string;
}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(mediaCloudActions.uploadingCover(true));

    const date = new Date();
    const dateForFileName = date.toJSON().replaceAll(':', '');

    try {
      const formData = new FormData();
      formData.append(
        'cover',
        albumData.imageData.dataBlob,
        albumData.imageData.name
      );
      formData.append('albumName', albumData.name);
      formData.append('albumSlug', `${albumData.slug}_${dateForFileName}`);
      const { fileName } = await httpRequest({
        url: `${backendUrl + backendUploadUrlPostfix}`,
        method: 'PUT',
        body: formData,
      });

      const graphqlQuery = {
        query: `
          mutation uploadAlbumCoverImage($name: String!, $releaseDate: Int!, $fileName: String!, $date: Date!){
            uploadAlbumCoverImage ( name: $name, releaseDate: $releaseDate, fileName: $fileName, date: $date ) {
              __typename
              ... on AlbumData {
              _id,
              name,
              slug,
              coverImage,
              releaseDate,
              totalDiscs,
              date
              }
              ... on Error {
                  message
              }
          }
        }
        `,
        variables: {
          name: albumData.name,
          releaseDate: albumData.releaseDate,
          fileName,
          date,
        },
      };

      await httpRequest({
        url: `${backendUrl + backendGraphqlUrlPostfix}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      dispatch(mediaCloudActions.uploadingCover(false));
    } catch (err) {
      dispatch(mediaCloudActions.uploadingCover(false));
      dispatch(mediaCloudActions.setUploadingCoverError(err.message));
    }
  };
};
