import { useDispatch } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import Slider from '@react-native-community/slider';
import { Icon } from 'react-native-elements';
import { Audio } from 'expo-av';

import { Layout } from '../../../../constants/Layout';
import { appleColors } from '../../../../constants/Colors';

import { mediaCloudActions } from '../../../../store/MediaCloud/mediaCloud-slice';

type VolumeSliderProps = {
  audioInstance: Audio.Sound;
  playbackStatus: {
    isLoaded: Boolean;
  };
  volume?: number;
};

export const VolumeSlider = (props: VolumeSliderProps) => {
  const { audioInstance, playbackStatus, volume } = props;

  const onValueChangeHandler = (value) => {
    if (playbackStatus.isLoaded) {
      audioInstance.setVolumeAsync(value);
    }
  };

  const dispatch = useDispatch();
  const onSlidingCompleteHandler = async (value) => {
    dispatch(mediaCloudActions.setVolume(value));
  };

  return (
    <View style={styles.playerVolumeSlider}>
      <Icon name={'volume-off'} type={'ionicon'} size={20} />
      <Slider
        style={styles.slider}
        minimumValue={0}
        maximumValue={1}
        value={volume}
        step={0.01}
        onValueChange={onValueChangeHandler}
        onSlidingComplete={onSlidingCompleteHandler}
        minimumTrackTintColor={appleColors.Blue.Light}
        maximumTrackTintColor={appleColors.Gray_5.Light}
      />
      <Icon name={'volume-high'} type={'ionicon'} size={20} />
    </View>
  );
};

const styles = StyleSheet.create({
  playerVolumeSlider: {
    height: 40,
    marginHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    //borderColor: 'green',
    //borderWidth: 1,
  },
  slider: {
    marginHorizontal: 10,
    width: '100%',
  },
});
