import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import {
  ActivityIndicator,
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { Icon } from 'react-native-elements';
import { MediaPlayer } from '../../../components/MediaPlayer/MediaPlayer';

import { appleColors, selectedColors } from '../../../constants/Colors';
import { Layout } from '../../../constants/Layout';
import { getImageSet } from '../../../utils/getImageSet';

import { getMovies } from '../../../store/MediaCloud/mediaCloud-actions';
import { mediaCloudActions } from '../../../store/MediaCloud/mediaCloud-slice';

import {
  backendUrl,
  backendMediaCloudMoviesStorageUrlPostfix,
} from 'react-native-dotenv';

export default function CinemaScreen({ route }: any) {
  const movieData = useSelector(
    (state: RootState) => state.mediaCloud.movieData
  );
  const loading = useSelector(
    (state: RootState) => state.mediaCloud.movieDataLoading
  );
  const error = useSelector(
    (state: RootState) => state.mediaCloud.movieDataError
  );

  const dispatch = useDispatch();
  const loadMovie = useCallback(() => {
    dispatch(getMovies(route.params?.movie_id));
  }, []);

  useEffect(() => {
    loadMovie();

    return () => {
      dispatch(mediaCloudActions.movieLoading(true));
    };
  }, []);

  const movie = !loading ? movieData[0] : undefined;

  const Genre = () => {
    return movie.IMDbData.genres.map((genre, index) => (
      <View key={index} style={styles.movieGenre}>
        <Text>{genre}</Text>
      </View>
    ));
  };

  const Cast = () => {
    return movie.IMDbData.cast.map((cast, index) => (
      <View key={index} style={styles.movieCastElement}>
        <View key={index} style={styles.movieCastAvatar}>
          {cast.avatar !== '' ? (
            <Image
              style={styles.image}
              source={{
                uri: cast.avatar,
              }}
              resizeMode='cover'
            />
          ) : (
            <Icon name={'person'} type={'ionicon'} size={40} />
          )}
        </View>
        <Text style={styles.movieCastNameText}>{cast.actor_name}</Text>
        <Text
          style={[
            styles.grayText,
            styles.movieCastCharacterText,
          ]}
        >
          {cast.character}
        </Text>
      </View>
    ));
  };

  const director = !loading
    ? movie.IMDbData.director.map((director, index) => {
        return index < 3 ? (index > 0 ? `, ${director}` : director) : undefined;
      })
    : undefined;

  const coverImageSource = !loading
    ? getImageSet(movieData[0].IMDbData.poster)
    : undefined;

  return (
    <View>
      {loading ? (
        <ActivityIndicator
          size={Layout.isMobileDevice ? 'small' : 'large'}
          style={{ marginTop: Layout.isMobileDevice ? 5 : 20 }}
        />
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={true}
          style={styles.container}
        >
          <View style={styles.movieHeader}>
            <Text style={styles.movieTitleText}>
              {movieData[0].IMDbData.title}
            </Text>
            <Text
              style={{ ...styles.grayText, ...styles.movieMetaText }}
            >{`${movieData[0].IMDbData.genres[0]}  ${movieData[0].IMDbData.year}  ${movieData[0].IMDbData.runtime}`}</Text>
            <Text
              style={styles.grayText}
            >{`IMDb Rating: ${movieData[0].IMDbData.IMDbRating}/10`}</Text>
          </View>
          <View style={styles.movieContainer}>
            <View style={styles.moviePoster}>
              <Image
                style={styles.coverImage}
                source={coverImageSource[2]}
                resizeMode='cover'
              />
            </View>
            <View style={styles.moviePlayer}>
              <MediaPlayer
                style={{
                  width: '100%',
                  height: 420,
                }}
                videoMedia={{
                  title: movieData[0].title,
                  posterImage: movieData[0].posterImage,
                  mediaFile: `${
                    backendUrl + backendMediaCloudMoviesStorageUrlPostfix
                  }/${movie.mediaFile}`,
                }}
              />
            </View>
          </View>

          <View style={styles.movieInfo}>
            <View style={styles.movieGenreAndPlot}>
              <View style={styles.movieGenres}>
                <Genre />
              </View>
              <ScrollView
                style={styles.moviePlotScroll}
                showsVerticalScrollIndicator={false}
              >
                <Text style={styles.grayText}>
                  {movieData[0].IMDbData.plot}
                </Text>
              </ScrollView>
            </View>
          </View>
          <View>
            <View style={styles.movieCastHeader}>
              <View style={styles.sectionHeaderDesignElement} />
              <Text style={styles.sectionHeaderText}>Cast</Text>
            </View>
            <View>
              <ScrollView horizontal style={{ marginHorizontal: 5 }}>
                <Cast />
              </ScrollView>
            </View>
            <View style={styles.movieDirectorContainer}>
              <Text>Director: </Text>
              <Text style={styles.grayText}>{director}</Text>
            </View>
          </View>
          <View style={{ marginTop: 80 }} />
        </ScrollView>
      )}
    </View>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 100,
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  movieContainer: {
    flexDirection: 'row',
    height: 420,
    width: '100%',
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  moviePoster: {
    padding: 1,
    width: '25%',
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  moviePlayer: {
    padding: 1,
    width: '75%',
  },
  coverImage: {
    width: 284,
    height: 420,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  grayText: {
    color: appleColors.Gray_1.Light,
  },
  sectionHeaderDesignElement: {
    width: 5,
    backgroundColor: selectedColors.IMDbYellow,
  },
  sectionHeaderText: {
    paddingLeft: 5,
    fontWeight: 'bold',
    fontSize: 18,
  },
  movieHeader: {
    height: 100,
    width: Layout.window.width,
    paddingLeft: 10,
  },
  movieTitleText: {
    paddingTop: 15,
    paddingBottom: 10,
    fontSize: 22,
  },
  movieMetaText: { paddingBottom: 3 },
  movieInfo: {
    flexDirection: 'row',
    marginBottom: 15,
    height: 100,
    borderColor: borderOn ? 'green' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  movieGenreAndPlot: {
    padding: 10,
    width: '100%',
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  movieGenres: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  movieGenre: {
    height: 25,
    paddingHorizontal: 6,
    marginHorizontal: 2.5,
    justifyContent: 'center',
    borderWidth: 1.5,
    borderColor: appleColors.Gray_4.Light,
    borderRadius: 3,
  },
  moviePlotScroll: {
    height: '80%',
  },
  movieCastHeader: { flexDirection: 'row', padding: 10, marginBottom: 10 },
  movieCastElement: {
    width: 90,
    marginHorizontal: 5,
  },
  movieCastAvatar: {
    width: 90,
    height: 90,
    justifyContent: 'center',
    backgroundColor: appleColors.Gray_6.Light,
  },
  movieCastNameText: { fontSize: 12, textAlign: 'center' },
  movieCastCharacterText: { fontSize: 11, marginBottom: 8, textAlign: 'center' },
  movieDirectorContainer: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 10,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: appleColors.Gray_6.Light,
  },
});
