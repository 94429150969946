import { useDispatch } from 'react-redux';
import { useLinkTo } from '@react-navigation/native';
import {
  TouchableWithoutFeedback,
  View,
  Text,
  Image,
  Platform,
  StyleSheet,
} from 'react-native';
import { Icon } from 'react-native-elements';

import { CustomButton } from '../../../components/CustomButton';
import fileUpload from '../../../utils/fileUpload';

import { appleColors, selectedColors } from '../../../constants/Colors';

import { uploadCoverImage } from '../../../store/MediaCloud/mediaCloud-actions';

import {
  backendUrl,
  backendMediaCloudAlbumsStorageUrlPostfix,
} from 'react-native-dotenv';

export const AlbumElement = ({
  item,
  albumWidth,
  albumMarginHorizontal,
  setShowDeleteConfirmation,
}: any) => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();

  const coverImage = `${
    backendUrl + backendMediaCloudAlbumsStorageUrlPostfix
  }/${item.name}/${item.coverImage}`;

  const getAlbumLength =
    item.tracks.length !== 0
      ? item.tracks
          .map((track) => {
            return track.duration;
          })
          .reduce((accumulator, currentValue) => accumulator + currentValue)
      : 0;
  const minutes = Math.floor(getAlbumLength / 60);
  const seconds = Math.floor(getAlbumLength - minutes * 60);
  const albumDuration = `${minutes}m ${seconds}s`;

  const tracks = item.tracks.slice().sort((a, b) => {
    const na = a.discNumber * 1000 + a.trackNumber;
    const nb = b.discNumber * 1000 + b.trackNumber;
    return na - nb;
  });

  const openAlbum = () => {
    linkTo(`/mediacloud/music/playlist/${item._id}`);
  };

  const artist = item.artist.map((artist, index) => {
    return index < 3
      ? index > 0
        ? `, ${artist.name}`
        : artist.name
      : undefined;
  });

  return (
    <TouchableWithoutFeedback onPress={openAlbum}>
      <View
        style={[
          styles.album,
          {
            width: albumWidth,
            height: albumWidth * 2,
            marginHorizontal: albumMarginHorizontal / 2,
          },
        ]}
      >
        <View style={styles.albumCoverContainer}>
          <View style={styles.albumControls}>
            <CustomButton
              turnOnFloat
              titleObject={
                <Icon name={'file-upload'} type={'material'} size={25} />
              }
              //disabled={uploadingCover ? true : false}
              onPress={async () => {
                const cover = await fileUpload({
                  type: 'image/*',
                  copyToCacheDirectory: false,
                  multiple: false,
                });
                dispatch(
                  uploadCoverImage({
                    imageData: cover[0],
                    name: item.name,
                    releaseDate: item.releaseDate,
                    slug: item.slug,
                  })
                );
              }}
              textStyle={{
                fontSize: 16,
                color: selectedColors.White,
                fontFamily: 'sf-pro',
              }}
              button={{
                height: 30,
                width: 30,
                color: appleColors.Blue.Dark,
                borderRadius: 5,
              }}
              position={{
                top: 3,
                right: 36,
              }}
            />
            <CustomButton
              turnOnFloat
              titleObject={<Icon name={'delete'} type={'material'} size={25} />}
              //disabled={loading ? true : false}
              onPress={() => {
                setShowDeleteConfirmation({
                  name: item.name,
                  releaseDate: item.releaseDate,
                });
              }}
              textStyle={{
                fontSize: 16,
                color: selectedColors.White,
                fontFamily: 'sf-pro',
              }}
              button={{
                height: 30,
                width: 30,
                color: appleColors.Red.Dark,
                borderRadius: 5,
              }}
              position={{
                top: 3,
                right: 3,
              }}
            />
          </View>
          <View style={styles.albumCover}>
            {/* <Image
              style={styles.albumCoverImage}
              source={{
                uri: coverImage,
              }}
              resizeMode='cover'
            /> */}
          </View>
        </View>
        <View style={styles.albumTitleAndArtist}>
          <Text style={styles.albumTitleText}>{item.name}</Text>
          <Text style={styles.albumArtistText}>{artist}</Text>
        </View>
        <View style={styles.albumDetails}>
          <View style={styles.albumDetailsRow}>
            <View style={styles.albumDetailsYear}>
              <Icon
                name={Platform.OS === 'android' ? 'event' : 'calendar'}
                type={Platform.OS === 'android' ? 'material' : 'ionicon'}
                size={35}
              />
              <Text>{item.releaseDate}</Text>
            </View>
            <View style={styles.albumDetailsDisc}>
              <Icon
                name={Platform.OS === 'android' ? 'album' : 'disc'}
                type={Platform.OS === 'android' ? 'material' : 'ionicon'}
                size={35}
              />
              <Text>{`${item.totalDiscs} disc`}</Text>
            </View>
          </View>
          <View style={styles.albumDetailsRow}>
            <View style={styles.albumDetailsTrack}>
              <Icon name={'play'} type={'ionicon'} size={35} />
              <Text>{`${item.tracks.length} track`}</Text>
            </View>
            <View style={styles.albumDetailsRuntime}>
              <Icon
                name={Platform.OS === 'android' ? 'schedule' : 'time-outline'}
                type={Platform.OS === 'android' ? 'material' : 'ionicon'}
                size={35}
              />
              <Text>{albumDuration}</Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const borderOn = false;
const borderRadius = 5;
const fontFamily = 'sf-pro';

const styles = StyleSheet.create({
  album: {
    cursor: 'pointer',
    marginVertical: 20,
    borderRadius: borderRadius,
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.3,
    shadowRadius: 9,
  },
  albumCoverContainer: {
    height: '50%',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumControls: {
    zIndex: 1,
  },
  albumCover: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumCoverImage: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
  },
  albumTitleAndArtist: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '20%',
    paddingHorizontal: '5%',
    borderColor: borderOn ? 'green' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumTitleText: {
    fontFamily: 'sf-pro-bold',
    fontSize: 18,
  },
  albumArtistText: {
    fontFamily,
    fontSize: 16,
  },
  albumDetails: {
    justifyContent: 'space-evenly',
    height: '30%',
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumDetailsRow: {
    height: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  },
  albumDetailsYear: {
    alignItems: 'center',
    width: '50%',
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumDetailsDisc: {
    alignItems: 'center',
    width: '50%',
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumDetailsTrack: {
    alignItems: 'center',
    width: '50%',
    borderColor: borderOn ? 'green' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumDetailsRuntime: {
    alignItems: 'center',
    width: '50%',
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
});
