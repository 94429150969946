import * as DocumentPicker from 'expo-document-picker';

const b64toBlob = (dataURI: any, type: string) => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
};

export default async function fileUpload(uploadConfig: {
  type: string;
  copyToCacheDirectory?: boolean;
  multiple?: boolean;
}): Promise<unknown[]> {
  const { output } = await DocumentPicker.getDocumentAsync({ ...uploadConfig });
  const files: FileList = output;

  return Promise.all(
    Object.keys(files).map(
      (_key, i) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          const file = files[i];

          reader.onload = () => {
            resolve({
              dataBlob: b64toBlob(reader.result, file.type),
              name: file.name,
              size: file.size,
            });
          };
          reader.readAsDataURL(file);
        })
    )
  );
}
