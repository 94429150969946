import { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import {
  ActivityIndicator,
  View,
  Image,
  FlatList,
  SectionList,
  StyleSheet,
} from 'react-native';
import { MediaPlayer } from '../../../components/MediaPlayer/MediaPlayer';
import { Separator } from '../../../components/Separator';

import { TrackListElement, TrackListHeaderElement } from './TrackListElement';

import { getAlbums } from '../../../store/MediaCloud/mediaCloud-actions';

import { customArrayFunctions } from '../../../utils/customArrayFunctions';
import { Layout } from '../../../constants/Layout';

import { mediaCloudActions } from '../../../store/MediaCloud/mediaCloud-slice';

import {
  backendUrl,
  backendMediaCloudAlbumsStorageUrlPostfix,
} from 'react-native-dotenv';

export default function PlayListScreen({ route }: any) {
  const albumData = useSelector(
    (state: RootState) => state.mediaCloud.albumData
  );
  const loading = useSelector(
    (state: RootState) => state.mediaCloud.albumDataLoading
  );
  const error = useSelector(
    (state: RootState) => state.mediaCloud.albumDataError
  );
  const volume = useSelector((state: RootState) => state.mediaCloud.volume);

  const flatlistRef = useRef();
  const sectionListRef = useRef();
  const [media, setMedia] = useState(Object);

  const dispatch = useDispatch();
  const loadAlbum = useCallback(() => {
    dispatch(getAlbums(route.params?.album_id));
  }, []);

  useEffect(() => {
    loadAlbum();

    return () => {
      dispatch(mediaCloudActions.albumLoading(true));
    };
  }, []);

  useEffect(() => {
    !loading ? setTrack(multiDiscList[0].data[0]) : undefined;
  }, [loading]);

  const setTrack = (item: any, sectionIndex?: number, index?: number) => {
    const mediaFile =
      album.totalDiscs > 1
        ? `${backendUrl + backendMediaCloudAlbumsStorageUrlPostfix}/${
            album.name
          }/Disc ${item.discNumber}/${item.mediaFile}`
        : `${backendUrl + backendMediaCloudAlbumsStorageUrlPostfix}/${
            album.name
          }/${item.mediaFile}`;

    if (album.totalDiscs > 1) {
      sectionListRef.current.scrollToLocation({
        animated: true,
        sectionIndex,
        itemIndex: index ? index + 1 : 0,
      });
    } else {
      flatlistRef.current.scrollToIndex({
        animated: true,
        index: index ? index : 0,
      });
    }

    setMedia({
      _id: item._id,
      disc: item.discNumber,
      track: item.trackNumber,
      title: item.name,
      mediaFile,
    });
  };

  const album = albumData[0];
  let multiDiscList: any;
  let coverImage: any;
  if (!loading) {
    multiDiscList = customArrayFunctions.regroupArrayByData(
      album.tracks,
      'discNumber'
    );
    multiDiscList.map((disc) =>
      disc.data.sort((a, b) => {
        if (a.trackNumber < b.trackNumber) {
          return -1;
        }
        if (a.trackNumber > b.trackNumber) {
          return 1;
        }
        return 0;
      })
    );

    coverImage = `${backendUrl + backendMediaCloudAlbumsStorageUrlPostfix}/${
      album.name
    }/${album.coverImage}`;
  }

  const playPrevious = () => {
    if (
      albumData[0].tracks.length > 100 &&
      media.disc === 1 &&
      media.track === 1
    ) {
      return;
    }
    if (media.track > 1) {
      setTrack(
        multiDiscList[media.disc - 1].data[media.track - 2],
        media.disc - 1,
        media.track - 2
      );
    } else if (media.disc === 1) {
      setTrack(
        multiDiscList[album.totalDiscs - 1].data[
          multiDiscList[album.totalDiscs - 1].data.length - 1
        ],
        album.totalDiscs - 1,
        multiDiscList[album.totalDiscs - 1].data.length - 1
      );
    } else {
      setTrack(
        multiDiscList[media.disc - 2].data[
          multiDiscList[media.disc - 2].data.length - 1
        ],
        media.disc - 2,
        multiDiscList[media.disc - 2].data.length - 1
      );
    }
  };

  const nextTrack = () => {
    if (multiDiscList[media.disc - 1].data.length > media.track) {
      setTrack(
        multiDiscList[media.disc - 1].data[media.track],
        media.disc - 1,
        media.track
      );
    } else if (multiDiscList.length > media.disc) {
      setTrack(multiDiscList[media.disc].data[0], media.disc, 0);
    } else {
      setTrack(multiDiscList[0].data[0], 0, 0);
    }
  };

  const renderItem = (item: any, index: number) => {
    return (
      <TrackListElement
        item={item}
        selected={item._id === media._id}
        onPress={() => setTrack(item, item.discNumber - 1, index)}
      />
    );
  };

  return (
    <View style={[
      styles.container,
      Layout.desktop ?
      {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
      } :
      {}
      ]}>
      {loading ? (
        <ActivityIndicator
          size={Layout.isMobileDevice ? 'small' : 'large'}
          style={{ marginTop: Layout.isMobileDevice ? 5 : 20 }}
        />
      ) : (
        <Fragment>
          <View style={[
            styles.albumCover,
            Layout.desktop ?
            { 
              width: 500,
              height: 500,
              margin: 20,
              borderRadius: 15
            } :
            {
              width: 300,
              height: 300,
              margin: 10,
              marginHorizontal: 'auto',
              borderRadius: 10
            }
            ]}>
            <Image
              style={styles.albumCoverImage}
              source={{
                uri: coverImage,
              }}
              resizeMode='cover'
            />
          </View>
          <View style={styles.tracklistContainer}>
            {album.totalDiscs > 1 ? (
              <SectionList
                ref={sectionListRef}
                sections={multiDiscList}
                keyExtractor={(item) => item._id}
                renderSectionHeader={(item) => (
                  <TrackListHeaderElement section={item.section} />
                )}
                ItemSeparatorComponent={() => <Separator />}
                renderItem={({ item, index }) => renderItem(item, index)}
                initialNumToRender={30}
                extraData={media._id}
              />
            ) : (
              <FlatList
                ref={flatlistRef}
                data={multiDiscList[0].data}
                keyExtractor={(item) => item._id}
                renderItem={({ item, index }) => renderItem(item, index)}
                ItemSeparatorComponent={() => <Separator />}
                initialNumToRender={30}
                extraData={media._id}
              />
            )}
            <MediaPlayer
              style={styles.mediaPlayer}
              audioMedia={media}
              playPrevious={playPrevious}
              playNext={nextTrack}
              volume={volume}
            />
          </View>
        </Fragment>
      )}
    </View>
  );
}

const borderOn = false;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumCover: {
    borderColor: borderOn ? 'orange' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  albumCoverImage: {
    width: '100%',
    height: '100%',
    borderRadius: 15,
  },
  tracklistContainer: {
    flex: 1,
    height: '100%',
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  mediaPlayer: {
    width: '100%',
    height: 150,
  },
});
