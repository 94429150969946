import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import {
  ScrollView,
  View,
  TouchableWithoutFeedback,
  Image,
  Text,
  Linking,
  StyleSheet,
} from 'react-native';
import { Icon } from 'react-native-elements';

import { appleColors, selectedColors } from '../../../constants/Colors';
import { getCV } from '../../../store/Introduction/introduction-actions';
import { CustomButton } from '../../../components/CustomButton';

import { CVFormLanguage } from './CVFormLanguage';
import CircleDecoration from './CircleDecoration';

import { Layout } from '../../../constants/Layout';

import {
  backendUrl,
  backendPublicFileStorageUrlPostfix,
} from 'react-native-dotenv';

export default function CVScreen() {
  const introductionData = useSelector(
    (state: RootState) => state.introduction
  );
  const [cVLanguage, setCVLanguage] = useState<'eng' | 'hun'>('eng');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCV(cVLanguage));
  }, [cVLanguage, dispatch]);

  const changeCVLanguage = () => {
    cVLanguage === 'eng' ? setCVLanguage('hun') : setCVLanguage('eng');
  };

  const CVData = !introductionData.CVLoading
    ? introductionData.CVData[0]
    : null;

  const LeftContainerElement = (props: {
    type: 'Details' | 'Skills' | 'Hobbies' | 'Languages';
  }) => {
    const ArrayElement = (props: any) => {
      const { elementName, index } = props;
      return (
        <Fragment>
          <Text
            style={[
              styles.leftContainerContentText,
              { marginTop: index !== 0 ? 10 : 0 },
            ]}
          >
            {elementName.name}
          </Text>
          <View
            style={{
              marginTop: 5,
              height: 5,
              width: 100,
              backgroundColor: grayColor,
            }}
          >
            <View
              style={{
                height: 5,
                width: elementName.knowledgementPercentage,
                backgroundColor: selectedColors.Black,
              }}
            />
          </View>
        </Fragment>
      );
    };

    return (
      <Fragment>
        <View
          style={[
            styles.leftContainerHeader,
            {
              marginTop:
                props.type === 'Details'
                  ? Layout.isMobileDevice
                    ? 7
                    : 10
                  : 40,
            },
          ]}
        >
          <CircleDecoration />
          <Text style={styles.containerPrimaryHeaderText}>
            {`${
              CVFormLanguage[`groupTitle${props.type}`][cVLanguage]
            }`.toLocaleUpperCase()}
          </Text>
          <CircleDecoration />
        </View>
        {props.type === 'Details' ? (
          <Fragment>
            <Text style={styles.leftContainerContentText}>
              {CVData.address}
            </Text>
            <Text style={styles.leftContainerContentText}>
              {CVData.phoneNumber}
            </Text>
            <Text style={styles.leftContainerContentText}>{CVData.email}</Text>
            <Text style={styles.leftContainerSecondaryHeaderText}>
              {`${CVFormLanguage.labelDatePlaceOfBirth[cVLanguage]}`.toLocaleUpperCase()}
            </Text>
            <Text style={styles.leftContainerContentText}>
              {CVData.dateOfBirth.substring(0, 10)}
            </Text>
            <Text style={styles.leftContainerContentText}>
              {CVData.placeOfBirth}
            </Text>
            <Text style={styles.leftContainerSecondaryHeaderText}>
              {`${CVFormLanguage.labelNationality[cVLanguage]}`.toLocaleUpperCase()}
            </Text>
            <Text style={styles.leftContainerContentText}>
              {CVData.nationality}
            </Text>
            <Text style={styles.leftContainerSecondaryHeaderText}>
              {`${CVFormLanguage.labelDrivingLicense[cVLanguage]}`.toLocaleUpperCase()}
            </Text>
            <Text style={styles.leftContainerContentText}>
              {CVData.drivingLicence}
            </Text>
          </Fragment>
        ) : undefined}
        {props.type === 'Hobbies' ? (
          <Text style={styles.leftContainerContentText}>{CVData.hobbies}</Text>
        ) : undefined}
        {props.type === 'Skills'
          ? CVData.skills.map((skill: any, index: number) => {
              return (
                <ArrayElement key={skill.skillId} elementName={skill} index />
              );
            })
          : undefined}
        {props.type === 'Languages'
          ? CVData.languages.map((language: any, index: number) => {
              return (
                <ArrayElement
                  key={language.languageId}
                  elementName={language}
                  index
                />
              );
            })
          : undefined}
      </Fragment>
    );
  };

  const Badges = (props: {
    url: string;
    icon: {
      url: string;
      marginTop: number;
      size: { width: number; height: number };
    };
  }) => {
    return (
      <View
        style={{
          marginTop: props.icon.marginTop,
        }}
      >
        <TouchableWithoutFeedback onPress={() => Linking.openURL(props.url)}>
          <Image
            style={{
              width: props.icon.size.width,
              height: props.icon.size.height,
              cursor: 'pointer',
            }}
            source={{
              uri: `${backendUrl + backendPublicFileStorageUrlPostfix}${
                props.icon.url
              }`,
            }}
          />
        </TouchableWithoutFeedback>
      </View>
    );
  };

  const RightContainerElement = (props: {
    type: 'Work' | 'Education' | 'Course';
  }) => {
    const ArrayElement = (props: any) => {
      const { elementName, title, description, duration } = props;
      return (
        <Fragment>
          <CircleDecoration />
          <View
            style={{
              marginLeft: Layout.isMobileDevice ? 7 : 15,
              paddingLeft: Layout.isMobileDevice ? 25 : 35,
              borderLeftWidth: 1,
              borderLeftColor: selectedColors.Black,
            }}
          >
            <Text style={[{...styles.rightContainerContentText, fontFamily: 'sf-pro-bold'}]}>
              {elementName[title]}
            </Text>
            {description && <Text style={[styles.rightContainerContentText]}>
              {elementName[description]}
            </Text>}
            <Text
              style={[styles.rightContainerContentText, { color: grayColor }]}
            >
              {elementName[duration]}
            </Text>
            {elementName.degree && (
              <Text
                style={{
                  fontSize: fontSizes.small,
                  fontFamily: 'sf-pro-bold',
                  color: appleColors.Gray_2.Dark,
                  marginBottom: 20,
                }}
              >
                {`${CVFormLanguage.degreeTitle[cVLanguage]}: ${elementName.degree}`}
              </Text>
            )}
          </View>
        </Fragment>
      );
    };

    return (
      <Fragment>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop:
              props.type === 'Work'
                ? undefined
                : Layout.isMobileDevice
                ? 20
                : 40,
            marginBottom: 10,
          }}
        >
          <Icon
            name={
              props.type === 'Work'
                ? 'briefcase'
                : props.type === 'Education'
                ? 'school'
                : props.type === 'Course'
                ? 'class'
                : ''
            }
            type={props.type === 'Course' ? 'material' : 'ionicon'}
            size={fontSizes.larger}
          />
          <Text
            style={[
              styles.containerPrimaryHeaderText,
              { marginLeft: Layout.isMobileDevice ? 10 : 20 },
            ]}
          >
            {`${
              CVFormLanguage[`groupTitle${props.type}`][cVLanguage]
            }`.toLocaleUpperCase()}
          </Text>
        </View>
        {props.type === 'Work'
          ? CVData.employmentHistory.map((employment: any) => {
              return (
                <ArrayElement
                  key={employment.employmentHistoryId}
                  elementName={employment}
                  title={'jobTitle'}
                  description={'description'}
                  duration={'durationOfEmployment'}
                />
              );
            })
          : undefined}
        {props.type === 'Education'
          ? CVData.education.map((education: any) => {
              return (
                <ArrayElement
                  key={education.educationId}
                  elementName={education}
                  title={'institution'}
                  description={'description'}
                  duration={'durationOfStudy'}
                />
              );
            })
          : undefined}
        {props.type === 'Course'
          ? CVData.courses.map((course: any) => {
              return (
                <ArrayElement
                  key={course.courseId}
                  elementName={course}
                  title={'name'}
                  description={'description'}
                  duration={'durationOfStudy'}
                />
              );
            })
          : undefined}
      </Fragment>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.configurationContainer}>
        <View>
          {Layout.isMobileDevice ? undefined : (
            <Text style={styles.configurationContainerText}>
              {CVFormLanguage.languageChangeText[cVLanguage]}
            </Text>
          )}
          <CustomButton
            title={`${CVFormLanguage.languageChangeBtnText[cVLanguage]}`.toLocaleUpperCase()}
            onPress={() => changeCVLanguage()}
            disabled={introductionData.CVLoading}
            textStyle={styles.buttonText}
            button={{
              height: Layout.isMobileDevice ? 35 : 50,
              width: Layout.isMobileDevice ? '100%' : 200,
              color: appleColors.Gray_2.Dark,
              borderRadius: 25,
            }}
          />
        </View>
        <View
          style={{
            alignItems: 'flex-end',
            marginTop: Layout.isMobileDevice ? 5 : undefined,
          }}
        >
          {Layout.isMobileDevice ? undefined : (
            <Text style={styles.configurationContainerText}>
              {CVFormLanguage.printText[cVLanguage]}
            </Text>
          )}
          <CustomButton
            title={`${CVFormLanguage.printBtnText[cVLanguage]}`.toLocaleUpperCase()}
            onPress={() =>
              Linking.openURL(
                backendUrl +
                  backendPublicFileStorageUrlPostfix +
                  `/introduction/cv/Farkas_Andras_CV_${cVLanguage}.pdf`
              )
            }
            textStyle={styles.buttonText}
            button={{
              height: Layout.isMobileDevice ? 35 : 50,
              width: Layout.isMobileDevice ? '100%' : 300,
              color: appleColors.Gray_2.Dark,
              borderRadius: 25,
            }}
          />
        </View>
      </View>
      {!introductionData.CVLoading && (
        <ScrollView
          style={styles.cvContainer}
          showsVerticalScrollIndicator={Layout.isMobileDevice ? false : true}
        >
          <View
            style={{
              alignItems: 'center',
              marginBottom: Layout.isMobileDevice ? 30 : 50,
            }}
          >
            <Image
              style={styles.cvAvatar}
              source={{
                uri:
                  backendUrl +
                  backendPublicFileStorageUrlPostfix +
                  '/introduction/cv/cv_avatar.jpg',
              }}
            />
            <Text style={styles.personNameText}>
              {CVData.personName.toLocaleUpperCase()}
            </Text>
            <View style={styles.addressAndPhoneContainer}>
              <View style={styles.addressContainer}>
                <Icon name='location' type='ionicon' size={fontSizes.small} />
                <Text style={[styles.addressAndPhoneText, { marginLeft: 5 }]}>
                  {CVData.address.toLocaleUpperCase()}
                </Text>
              </View>
              <View style={styles.phoneContainer}>
                <Text style={styles.addressAndPhoneText}>
                  {CVData.phoneNumber.toLocaleUpperCase()}
                </Text>
              </View>
            </View>
            <View style={styles.cvContentContainer}>
              <View style={styles.cvContentLeftContainer}>
                <View style={styles.cvContentLeftInsideContainer}>
                  <LeftContainerElement type='Details' />
                  <LeftContainerElement type='Skills' />
                  <LeftContainerElement type='Hobbies' />
                  <LeftContainerElement type='Languages' />
                </View>
                <Badges
                  url={
                    'https://apps.apple.com/hu/developer/andras-farkas/id1602878509'
                  }
                  icon={{
                    url: '/introduction/cv/Apple_Certified_Developer.png',
                    marginTop: 100,
                    size: {
                      width: Layout.isMobileDevice ? 179 * 0.7 : 179,
                      height: Layout.isMobileDevice ? 60 * 0.7 : 60,
                    },
                  }}
                />
                <Badges
                  url={
                    'https://play.google.com/store/apps/developer?id=Andrew-Wolf'
                  }
                  icon={{
                    url: '/introduction/cv/Google_Play_Certified_Developer.png',
                    marginTop: 5,
                    size: {
                      width: Layout.isMobileDevice ? 179 * 0.7 : 179,
                      height: Layout.isMobileDevice ? 53 * 0.7 : 53,
                    },
                  }}
                />
              </View>
              <View style={styles.cvContentRightContainer}>
                <View style={styles.cvContentRightInsideContainer}>
                  <RightContainerElement type='Work' />
                  <RightContainerElement type='Education' />
                  <RightContainerElement type='Course' />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  );
}

const borderOn = false;
const fontFamily = 'sf-pro';
const grayColor = appleColors.Gray_2.Light;
const fontSizes = {
  small: Layout.isMobileDevice ? 10 : 16,
  medium: Layout.isMobileDevice ? 12 : 18,
  large: Layout.isMobileDevice ? 12 : 22,
  larger: Layout.isMobileDevice ? 18 : 30,
  extraLarge: Layout.isMobileDevice ? 20 : 37,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Layout.isMobileDevice ? 10 : 50,
    backgroundColor: selectedColors.White,
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  configurationContainer: {
    flexDirection: Layout.isMobileDevice ? 'column' : 'row',
    justifyContent: 'space-between',
    marginTop: Layout.isMobileDevice ? 10 : 50,
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
  },
  configurationContainerText: {
    fontSize: fontSizes.large,
    marginBottom: 10,
    fontFamily,
  },
  buttonText: {
    fontSize: fontSizes.small,
    color: selectedColors.White,
    fontFamily,
  },
  cvContainer: {
    marginTop: Layout.isMobileDevice ? 10 : 50,
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'red' : undefined,
  },
  cvAvatar: {
    width: 120,
    height: 120,
    borderRadius: 10,
  },
  personNameText: {
    marginTop: Layout.isMobileDevice ? 15 : 35,
    color: selectedColors.Black,
    fontSize: fontSizes.extraLarge,
    fontFamily,
    letterSpacing: 3,
  },
  addressAndPhoneContainer: {
    marginTop: Layout.isMobileDevice ? 20 : 40,
    height: 30,
    flexDirection: 'row',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  addressContainer: {
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderRightColor: grayColor,
    borderRightWidth: 1,
  },
  addressAndPhoneText: {
    color: grayColor,
    fontSize: fontSizes.small,
    fontFamily,
  },
  phoneContainer: {
    paddingLeft: 20,
    justifyContent: 'center',
  },
  cvContentContainer: {
    marginTop: Layout.isMobileDevice ? 20 : 40,
    width: Layout.isMobileDevice ? '100%' : 1000,
    flexDirection: 'row',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
  },
  cvContentLeftContainer: {
    width: '40%',
    alignItems: 'center',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'red' : undefined,
  },
  cvContentRightContainer: {
    width: '60%',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'orange' : undefined,
  },
  cvContentLeftInsideContainer: {
    width: Layout.isMobileDevice ? '100%' : 230,
    alignItems: 'center',
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'blue' : undefined,
  },
  cvContentRightInsideContainer: {
    borderWidth: borderOn ? 3 : undefined,
    borderColor: borderOn ? 'green' : undefined,
  },
  leftContainerHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  containerPrimaryHeaderText: {
    fontSize: fontSizes.medium,
    fontFamily,
    letterSpacing: 3,
  },
  leftContainerSecondaryHeaderText: {
    fontSize: fontSizes.small,
    fontFamily,
    marginTop: 25,
    marginBottom: 10,
    color: grayColor,
  },
  leftContainerContentText: {
    fontSize: fontSizes.small,
    fontFamily,
    textAlign: 'center',
    lineHeight: 25,
  },
  rightContainerContentText: {
    fontSize: fontSizes.small,
    fontFamily,
    marginBottom: 20,
  },
});
