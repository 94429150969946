export const CVFormLanguage = {
  languageChangeText: { eng: 'Change language:', hun: 'Nyelv módosítása:' },
  languageChangeBtnText: { eng: 'Hungarian', hun: 'Angol' },
  printText: {
    eng: 'If you are not environmentally friendly, then you can print it too:',
    hun: 'Ha nem vagy környezetbarát, akkor ki is nyomtathatod:',
  },
  printBtnText: {
    eng: 'Download printable PDF',
    hun: 'Nyomtatható PDF letöltése',
  },
  labelDatePlaceOfBirth: {
    eng: 'Date / Place of birth',
    hun: 'Dátum / Születési Hely',
  },
  labelNationality: { eng: 'Nationality', hun: 'Állampolgárság' },
  labelDrivingLicense: { eng: 'Driving License', hun: 'Jogosítvány' },
  groupTitleWork: { eng: 'Employment History', hun: 'Szakmai Tapasztalat' },
  groupTitleEducation: { eng: 'Education', hun: 'Tanulmányok' },
  degreeTitle: { eng: 'Degree', hun: 'Végzettség' },
  groupTitleCourse: { eng: 'Courses', hun: 'Kurzusok' },
  groupTitleDetails: { eng: 'Details', hun: 'Részletek' },
  groupTitleSkills: { eng: 'Skills', hun: 'Szakértelem' },
  groupTitleHobbies: { eng: 'Hobbies', hun: 'Hobbi' },
  groupTitleLanguages: { eng: 'Languages', hun: 'Nyelvismeret' },
};
