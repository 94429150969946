import { View, Text } from 'react-native';
import { Icon } from 'react-native-elements';

type PlayingTrackProps = {
  audioMedia: {
    title?: string;
    track?: string;
  };
};

export const PlayingTrack = (props: PlayingTrackProps) => {
  const { audioMedia } = props;

  return audioMedia?.title !== undefined ? (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        width: '12%',
      }}
    >
      <Icon name={'ios-musical-notes'} type={'ionicon'} size={20} />
      <Text
        style={{
          marginLeft: 0,
          fontSize: 14,
        }}
      >
        {audioMedia.track}
      </Text>
    </View>
  ) : null;
};
