import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  Animated,
  Platform,
} from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';

import { CustomButton } from '../../components/CustomButton';

import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

import { authActions } from '../../store/Auth/auth-slice';

import { Layout } from '../../constants/Layout';
import { appleColors, selectedColors } from '../../constants/Colors';

type AuthConfig = {
  graphqlBackendUrl: string;
  deviceTypeKey?: string;
  enableSignup: boolean;
};

export const Auth = (props: AuthConfig) => {
  const { graphqlBackendUrl, deviceTypeKey, enableSignup } = props;

  const [isInitializing, setIsInitializing] = useState(true);
  const [isSignup, setIsSignup] = useState(false);

  const { authError } = useSelector((state: RootState) => state.auth);

  const scrollView = useRef(null);

  const animation = useRef(new Animated.Value(0)).current;
  const opacity = animation.interpolate({
    inputRange: [0, Layout.window.width],
    outputRange: [1, 0],
  });
  const translateX = animation.interpolate({
    inputRange: [0, Layout.window.width],
    outputRange: [0, 30],
  });
  const translateY = animation.interpolate({
    inputRange: [0, Layout.window.height],
    outputRange: [Platform.OS === 'android' ? 3 : 0, -100],
  });

  const dispatch = useDispatch();
  useEffect(() => {
    const tryLogin = async () => {
      //const userData = await AsyncStorage.getItem('userData');
      const userData = '';
      // Get AUTH
      if (!userData) {
        setIsInitializing(false);
        return;
      }
      const transformedData = JSON.parse(userData);
      const { user } = transformedData;

      const expirationDate = new Date(user.tokenValidTo);

      if (expirationDate <= new Date(/* Date.now() + 1000 * 60 * 60 * 2 */)) {
        //AsyncStorage.removeItem('userData');
        // Delete AUTH
        setIsInitializing(false);
        return;
      }

      dispatch(
        authActions.authenticate({
          user: user,
        })
      );
    };

    tryLogin();
  }, [dispatch]);

  const changeForm = () => {
    setIsSignup((state) => !state);

    const scrollToWidth = Layout.isMobileDevice
      ? Layout.window.width * 1.8
      : Layout.window.width;
    scrollView.current?.scrollTo({
      x: isSignup ? -scrollToWidth : scrollToWidth,
      animated: true,
      duration: 500,
    });
  };

  return isInitializing ? (
    <ActivityIndicator size='large' />
  ) : (
    <View style={styles.authContainer}>
      <View style={styles.welcomeTitle}>
        <Animated.Text
          style={[
            { fontFamily: 'sf-pro-bold', fontSize: 35, fontWeight: 'bold' },
            {
              transform: [{ translateX: translateX }],
            },
          ]}
        >
          Welcome
        </Animated.Text>
        <Text> </Text>
        <Animated.Text
          style={[
            { fontFamily: 'sf-pro-bold', fontSize: 35 },
            {
              opacity: opacity,
              transform: [
                //{ translateX: translateX },
                { translateY: translateY },
              ],
            },
          ]}
        >
          Back
        </Animated.Text>
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          title={'Login'}
          disabled={!isSignup}
          onPress={changeForm}
          textStyle={{
            fontSize: 18,
            color: selectedColors.White,
            fontFamily: 'sf-pro',
          }}
          button={{
            height: 50,
            width: '50%',
            color: appleColors.Gray_4.Dark,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        />
        <CustomButton
          title={'Sign up'}
          disabled={isSignup}
          onPress={changeForm}
          textStyle={{
            fontSize: 18,
            color: selectedColors.White,
            fontFamily: 'sf-pro',
          }}
          button={{
            height: 50,
            width: '50%',
            color: appleColors.Gray_4.Dark,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          }}
        />
      </View>
      <View style={styles.forms}>
        <ScrollView
          ref={scrollView}
          style={{
            width: Layout.window.width,
            //borderWidth: 1,
          }}
          contentContainerStyle={{
            width: Layout.window.width * 2,
          }}
          horizontal
          onScroll={
            //({ nativeEvent }) => {
            //  console.log(nativeEvent.contentOffset.x);
            //  setAnimatedEvent(nativeEvent.contentOffset.x);
            //}
            Animated.event(
              [{ nativeEvent: { contentOffset: { x: animation } } }],
              { useNativeDriver: false }
            )
          }
          scrollEventThrottle={16}
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
        >
          <LoginForm config={{ graphqlBackendUrl, deviceTypeKey }} />
          <SignupForm
            isSignup={isSignup}
            graphqlBackendUrl={graphqlBackendUrl}
            enableSignup={enableSignup}
            changeForm={changeForm}
          />
        </ScrollView>
      </View>
      <AwesomeAlert
        show={authError ? true : false}
        title='An Error Occurred!'
        message={authError}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        //cancelText='No, cancel'
        confirmText='Ok'
        confirmButtonColor='#DD6B55'
        //onCancelPressed={}
        onConfirmPressed={() => {
          dispatch(authActions.errorConfirmation());
          dispatch(authActions.loading(false));
        }}
      />
    </View>
  );
};

const borderOn = false;

const styles = StyleSheet.create({
  authContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderColor: borderOn ? 'red' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  welcomeTitle: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: Layout.isMobileDevice ? '90%' : '50%',
    borderColor: borderOn ? 'blue' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  buttonContainer: {
    marginVertical: 10,
    width: Layout.isMobileDevice ? '90%' : '50%',
    flexDirection: 'row',
    borderColor: borderOn ? 'green' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
  forms: {
    justifyContent: 'center',
    height: 400,
    width: Layout.isMobileDevice ? '90%' : '50%',
    borderColor: borderOn ? 'yellow' : undefined,
    borderWidth: borderOn ? 3 : undefined,
  },
});
