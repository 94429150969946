import React, { useReducer, useCallback } from 'react';
import { RootState } from '../../store';
import { ActivityIndicator, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { CustomButton } from '../../components/CustomButton';
import { TextInput } from '../../components/TextInput';

import { appleColors, selectedColors } from '../../constants/Colors';

import {
  formValidatorReducer,
  formValidatorReducerActions,
} from '../../utils/formValidatorReducer';
import { login } from '../../store/Auth/auth-actions';
import { Layout } from '../../constants/Layout';

type LoginFormConfig = {
  config: {
    graphqlBackendUrl: string;
    deviceTypeKey?: string;
  };
};

const LoginForm = (props: LoginFormConfig) => {
  const { config } = props;

  const { authLoading, onlineState } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();

  const initLoginFormState = () => {
    return {
      inputValues: { email: '', password: '' },
      inputValidities: {
        email: false,
        password: false,
      },
      formIsValid: false,
    };
  };

  const [loginFormState, dispatchLoginFormState] = useReducer(
    formValidatorReducer,
    {},
    initLoginFormState
  );

  const loginFormInputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchLoginFormState({
        type: formValidatorReducerActions.InputUpdate,
        payload: {
          value: inputValue,
          isValid: inputValidity,
          input: inputIdentifier,
        },
      });
    },
    [dispatchLoginFormState]
  );

  const loginAction = () => {
    dispatch(
      login({
        ...config,
        userInput: {
          email: loginFormState.inputValues.email,
          password: loginFormState.inputValues.password,
        },
      })
    );
  };

  //console.log(`loginFormState`);
  //console.log(loginFormState);

  return (
    <View
      style={{
        width: Layout.isMobileDevice ? '90%' : '50%',
        paddingHorizontal: '0.5%',
      }}
    >
      <TextInput
        id='email'
        label='Email'
        placeholder={'example@email.com'}
        keyboardType='email-address'
        required
        email
        autoCapitalize='none'
        errorText='Please enter a valid email address.'
        onInputChange={loginFormInputChangeHandler}
        width={Layout.isMobileDevice ? '48%' : '49%'}
        //returnKeyType='next'
      />
      <TextInput
        id='password'
        label='Password'
        placeholder={'********'}
        keyboardType='default'
        secureTextEntry
        required
        minLength={8}
        autoCapitalize='none'
        errorText='Please enter a valid password.'
        onInputChange={loginFormInputChangeHandler}
        width={Layout.isMobileDevice ? '48%' : '49%'}
      />
      <CustomButton
        title={'Login'}
        titleObject={
          authLoading ? <ActivityIndicator size='small' /> : undefined
        }
        disabled={
          !onlineState
            ? true
            : !loginFormState.formIsValid
            ? true
            : authLoading
            ? true
            : false
        }
        onPress={() => loginAction()}
        textStyle={{
          fontSize: 18,
          color: selectedColors.White,
          fontFamily: 'sf-pro',
        }}
        button={{
          marginVertical: 20,
          height: 50,
          color: appleColors.Gray_4.Dark,
          width: Layout.isMobileDevice ? '48%' : '49%',
          borderRadius: 10,
        }}
      />
    </View>
  );
};

export default LoginForm;
