import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as React from 'react';

export const useCachedResources = () => {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'sf-pro': require('../assets/fonts/SF-Pro-Regular.ttf'),
          'sf-pro-bold': require('../assets/fonts/SF-Pro-Bold.ttf'),
          //'product-sans': require('../assets/fonts/Product Sans Regular.ttf'),
          //'product-sans-bold': require('../assets/fonts/Product Sans Bold.ttf'),
          //'product-sans-italic': require('../assets/fonts/Product Sans Italic.ttf'),
          //'product-sans-bold-italic': require('../assets/fonts/Product Sans Bold Italic.ttf'),
          //'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
          //lato: require('../assets/fonts/Lato-Regular.ttf'),
          //'lato-bold': require('../assets/fonts/Lato-Bold.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
};
